import request from "../axios"

const requests = {
  checkCrawledDomain: (payload) => {
    return request.post("/checkdomain", payload)
  },
  getDatasetList: (params) => {
    return request.get("/dataframe_list", { params })
  },
  createDataset: (payload) => {
    return request.post("/dataframe", payload)
  },
  getDataset: (datasetId) => {
    return request.get(`/dataframe/${datasetId}`)
  },
  updateDataset: (datasetId, payload) => {
    return request.put(`/dataframe/${datasetId}`, payload)
  },
  removeDataset: (datasetId) => {
    return request.delete(`/dataframe/${datasetId}`)
  },
  createSource: (payload) => {
    return request.post("/source", payload)
  },
  updateSource: (sourceId, payload) => {
    return request.put(`/source/${sourceId}`, payload)
  },
  removeSource: (sourceId) => {
    return request.delete(`/source/${sourceId}`)
  },
  getFile: (params) => {
    return request.get("/upload", { responseType: "arraybuffer", params })
  },
  uploadFile: (payload) => {
    return request.post("/upload", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },
  cacheUploadFiles: (payload) => {
    return request.post("/cache-upload", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },
}

export default requests
