// Essential Imports
import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"

// Component Imports
import { LoadingContext } from "../helper/LoadingContext"
import { SuccessContext, ErrorContext, WarningContext } from "../helper/AlertContext"
import { TitleContext } from "../helper/TitleContext"
import DataSourceDetailImport from "../components/children/DataSourceDetailImport"
import DataSourceDetailDatabase from "../components/children/DataSourceDetailDatabase"
import { datasetService } from "../api/services"
import request from "../api/axios"

// Stylesheet Imports
import "../styles/DataSourceDetail.css"

export default function DataSourceDetail() {
  const { dataframeId, sourcePath } = useParams()
  const navigate = useNavigate()
  const loadingContext = useContext(LoadingContext)
  const successContext = useContext(SuccessContext)
  const warningContext = useContext(WarningContext)
  const errorContext = useContext(ErrorContext)
  const { setTitle } = useContext(TitleContext)
  const [content, setContent] = useState("")
  const [table, setTable] = useState({ detail: {} })
  const [sortedTableNameList, setSortedTableNameList] = useState([])
  const [rows, setRows] = useState([])
  const [type, setType] = useState("")
  const [id, setId] = useState("")
  const [contextData, setContextData] = useState(null)

  // const formatContent = (rawContent) => {
  //   // 1. Add a double line break after every period
  //   let formatted = rawContent.replace(/\./g, '.\n\n');

  //   // 2. Insert space before words that start with a capital letter
  //   // without a preceding space, but avoid breaking multi-letter acronyms
  //   formatted = formatted.replace(/([^ ])([A-Z][A-Z]*[a-z]+)/g, '$1 $2');

  //   // 3. Remove spaces after a line break
  //   formatted = formatted.replace(/\n +/g, '\n');

  //   return formatted;
  // };

  const formatContent = (rawContent, format) => {
    let formatted = rawContent

    if (format === "csv") {
      formatted = formatted.replace(/!/g, "")
    }
    formatted = formatted.replace(/([^ ])([A-Z][A-Z]*[a-z]+)/g, "$1 $2")
    formatted = formatted.replace(/\n +/g, "\n")
    formatted = formatted.replace(/(\w+):\s*(.*)/g, (match, header, content) => {
      return `${header}: ${content}\n`
    })

    return formatted
  }

  const getSuffix = (fullString, separator) => {
    const index = fullString.lastIndexOf(separator)
    return fullString.slice(index)
  }

  const fetchData = async (dataURL, dataType) => {
    loadingContext.setIsLoading(true)
    try {
      const response = await datasetService.getFile({ file_url: dataURL })
      const processedContent = processData(response)
      setContent(formatContent(processedContent, dataType))
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      loadingContext.setIsLoading(false)
    }
  }

  const fetchDb = async (dbId) => {
    try {
      const response = await request.get(`/databaseinfo/${dbId}`)
      setTable(response.data.table_info)
      return response
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      loadingContext.setIsLoading(false)
    }
  }

  function decodeUTF8Base64(base64) {
    const raw = window.atob(base64)
    let result = ""
    for (let i = 0; i < raw.length; i++) {
      result += "%" + ("00" + raw.charCodeAt(i).toString(16)).slice(-2)
    }
    return decodeURIComponent(result)
  }

  function processData(response) {
    let binary = ""
    const bytes = new Uint8Array(response.data)
    bytes.forEach((byte) => (binary += String.fromCharCode(byte)))
    const base64String = window.btoa(binary)
    const resultObject = JSON.parse(decodeUTF8Base64(base64String))
    const decodedString = decodeUTF8Base64(resultObject.text)
    return decodedString
  }

  useEffect(() => {
    setSortedTableNameList(Object.keys(table.detail).sort())
  }, [table])

  useEffect(() => {
    loadingContext.setIsLoading(true)
    const contextData = JSON.parse(sessionStorage.getItem("context"))
    setContextData(contextData)
    const currentType = contextData.type
    setType(currentType)
    // File logic
    if (currentType === "csv") {
      const filePart = getSuffix(contextData.context, "file/")
      const fileName = contextData.name
      sessionStorage.setItem("dataSourceName", contextData.name)
      fetchData(filePart, "csv")
      setTitle(fileName)
    } else if (currentType === "file" || currentType === "text") {
      const filePart = getSuffix(contextData.context, "file/")
      const fileName = contextData.name
      sessionStorage.setItem("dataSourceName", contextData.name)
      fetchData(filePart, "file")
      setTitle(fileName)
      // Google Drive
    } else if (currentType === "google") {
      const filePart = getSuffix(contextData.context, "google/")
      const fileName = contextData.name
      sessionStorage.setItem("dataSourceName", contextData.name)
      fetchData(filePart, "file")
      setTitle(fileName)
      // Confluence logic
    } else if (currentType === "confluence") {
      const filePart = getSuffix(contextData.context, "confluence/")
      const fileName = contextData.name
      sessionStorage.setItem("dataSourceName", contextData.name)
      fetchData(filePart, "file")
      setTitle(fileName)
      // Page crawler logic
    } else if (currentType === "notion") {
      const filePart = getSuffix(contextData.context, "notion/")
      const fileName = contextData.name
      sessionStorage.setItem("dataSourceName", contextData.name)
      fetchData(filePart, "file")
      setTitle(fileName)
    } else if (currentType === "sharepoint") {
      const filePart = getSuffix(contextData.context, "sharepoint/")
      const fileName = contextData.name
      sessionStorage.setItem("dataSourceName", contextData.name)
      fetchData(filePart, "file")
      setTitle(fileName)
    } else if (currentType === "media") {
      const filePart = getSuffix(contextData.context, "media/")
      const fileName = contextData.name
      sessionStorage.setItem("dataSourceName", contextData.name)
      fetchData(filePart, "media")
      setTitle(fileName)
      // Page crawler logic
    } else if (currentType === "cp") {
      const pagePart = getSuffix(contextData.context[0].gcp_cloud_storage_url, "html/")
      const page = contextData.name
      sessionStorage.setItem("dataSourceName", contextData.name)
      fetchData(pagePart, "cp")
      setTitle(page)
      // Site crawler logic
    } else if (currentType === "cs") {
      sessionStorage.setItem("dataSourceName", contextData.context[0].url)
      setRows(
        contextData.context.map((website) => ({
          id: website.id,
          context: website.url,
          source: currentType,
          datafreshness: 0,
          status: website.status,
          update_time: website.update_time,
          content: website.gcp_cloud_storage_url,
        })),
      )
      setTitle(contextData.context[0].url)
      // DB logic
    } else if (currentType === "database") {
      const dbName = contextData.name
      sessionStorage.setItem("dataSourceName", contextData.name)
      fetchDb(contextData.id).then((response) => {
        setId(response.data.id)
      })
      setTitle(dbName)
    }
  }, [])

  const handleRowClick = (row) => {
    if (row.status === "p" || row.status === "f") {
      warningContext.setWarning(true)
      warningContext.setWarningMsg("Data sync in progress, please try again later.")
    } else if (row.status === "r") {
      const siteDetail = row.id
      const siteData = {
        context: row,
      }
      sessionStorage.setItem("site", JSON.stringify(siteData))
      navigate(`/data/${dataframeId}/${sourcePath}/${siteDetail}`)
    }
  }

  // Handle remove table column
  const handleDeleteColumn = async (dbId, selected) => {
    const tableNameList = Object.assign({}, table.detail)

    // Create a new list containing only the items that are NOT selected
    selected.map((index) => {
      const key = sortedTableNameList[index]
      delete tableNameList[key]
    })
    loadingContext.setIsLoading(true)
    try {
      await request.put(`/databaseinfo/${dbId}`, {
        table_info: {
          detail: tableNameList,
        },
      })
      successContext.setSuccess(true)
      successContext.setSuccessMsg("Table column successfully deleted.")
      fetchDb(dbId)
    } catch (error) {
      console.error(error)
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      loadingContext.setIsLoading(false)
    }
  }

  return contextData ? (
    contextData.source === "import" ? (
      <DataSourceDetailImport
        type={type}
        rows={rows}
        handleRowClick={handleRowClick}
        content={content}
        isLoading={loadingContext.isLoading}
      />
    ) : (
      <DataSourceDetailDatabase
        id={id}
        rows={rows}
        tableNameList={sortedTableNameList}
        tableSqlMapping={table.detail}
        isLoading={loadingContext.isLoading}
        fetchDb={fetchDb}
        handleDeleteColumn={handleDeleteColumn}
      />
    )
  ) : null
}
