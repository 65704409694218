import React, { useContext, useEffect, useState } from "react"
import { Autocomplete, Button, FormControlLabel, RadioGroup, Radio, Stack, Skeleton, TextField } from "@mui/material"
import { Refresh as RefreshIcon } from "@mui/icons-material"

import BasicSnippet from "../../items/BasicSnippet"
import BasicTooltip from "../../items/BasicTooltip"
import CustomChip from "../../items/ClickableChip"
import LocalLoadingBar from "../../items/LocalLoadingBar"
import SelectField from "../../items/SelectField"
import DiscreteSlider from "../../items/Slider"
import MixedTextArea from "../../items/MixedTextArea"
import { appService } from "../../../api/services"
import { ErrorContext } from "../../../helper/AlertContext"
import { AccountContext } from "../../../helper/AccountContext"
import { FUNCTION_DETAILS, TAVILY_SEARCH_PARAMS } from "../../../utils/constants"

import ActionDrawerStyles from "./ActionDrawer.module.css"

const SEARCH_DEPTH_OPTIONS = ["basic", "advanced"]

const RadioLabel = (props) => (
  <FormControlLabel sx={{ mr: 5 }} control={<Radio sx={{ p: 0, mr: 1.5 }} />} size="small" {...props} />
)

const FunctionDrawer = ({ availableVars, formData, sequence, isLoadingForm, onSubmit, setUnsaved }) => {
  const [functionType, setFunctionType] = useState("af")
  const [functionId, setFunctionId] = useState("")
  const [functionList, setFunctionList] = useState([])
  const [isDatachanged, setIsDataChanged] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [isEmptySystemPrompt, setIsEmptySystemPrompt] = useState(true)
  const [paramsInput, setParamsInput] = useState({
    query: "",
    search_depth: "basic",
    max_results: 5,
    include_images: false,
    include_answer: false,
    include_raw_content: false,
    include_domains: [],
    exclude_domains: [],
  })
  const [systemPromptInput, setSystemPromptInput] = useState("")
  const [allowFunctionList, setAllowFunctionList] = useState([])
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { fetchAccountStatus, setSubPrompt } = useContext(AccountContext)

  const handleOnBlur = (value) => {
    setSystemPromptInput(value)
  }
  const handleOnChange = (value) => {
    setIsEmptySystemPrompt(!value)
    setIsDataChanged(true)
  }
  const handleOnSubmit = () => {
    if (isDatachanged) {
      const payload = {
        componentId: formData.componentId,
        functionId,
      }

      if (selectedItem?.type === "params") {
        payload.params = {
          arguments: paramsInput,
        }
      } else {
        payload.systemPrompt = systemPromptInput
      }

      onSubmit(payload)
    } else {
      onSubmit()
    }
  }
  const handleGetFunctionList = async () => {
    setIsLoading(true)
    try {
      const newFunctionList = []
      const { data } = await appService.getFunctionList({ q: "vext", per_page: 20 })

      data.results.map((item) => {
        if (FUNCTION_DETAILS[item.name]) {
          newFunctionList.push({
            value: item.id,
            key: item.name,
            params_json: item.params_json,
            label: FUNCTION_DETAILS[item.name].name,
            text: FUNCTION_DETAILS[item.name].description,
            sample_input: FUNCTION_DETAILS[item.name].sample_input,
            sample_output: FUNCTION_DETAILS[item.name].sample_output,
            type: FUNCTION_DETAILS[item.name].type || "",
          })
        }
      })
      setFunctionList(newFunctionList)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    }
  }

  useEffect(() => {
    if (functionId) {
      setIsDataChanged(formData.id !== functionId)
    }
  }, [functionId])

  useEffect(() => {
    setIsDisabled(!functionId || isEmptySystemPrompt)
  }, [functionId, isEmptySystemPrompt])

  useEffect(() => {
    if (formData.id) {
      setFunctionType(formData.componentType)
      setFunctionId(formData.id)
      if (Object.keys(formData.params).length) {
        setParamsInput(formData.params.arguments)
      } else {
        setSystemPromptInput(formData.systemPrompt)
      }
    }
  }, [formData])

  useEffect(() => {
    if (functionList.length && formData.id) {
      setSelectedItem(functionList.find((item) => item.value === formData.id))
    }
  }, [functionList])
  useEffect(() => {
    setUnsaved(isDatachanged)
  }, [isDatachanged])
  useEffect(() => {
    const asyncUseEffect = async () => {
      await handleGetFunctionList()
      await fetchAccountStatus((account) => {
        setAllowFunctionList(account.allowFunctions)
        setFunctionList((prevValue) =>
          prevValue.map((item) => (account.allowFunctions.includes(item.key) ? item : { ...item, badge: "pro" })),
        )
        setIsLoading(false)
      })
    }

    asyncUseEffect()
  }, [])

  return (
    <>
      <section style={{ position: "relative" }}>
        <LocalLoadingBar localLoading={isLoading || isLoadingForm} />
      </section>
      {isLoading ? (
        <Stack spacing={2} sx={{ padding: "1.5rem 2rem" }}>
          <Skeleton variant="rounded" animation="wave" height={85} />
          <Skeleton variant="rounded" animation="wave" height={70} />
          <Skeleton variant="rounded" animation="wave" height={200} />
        </Stack>
      ) : (
        <>
          <Button
            sx={{ position: "absolute", top: "1.25rem", right: "1.5rem", zIndex: 2 }}
            onClick={handleOnSubmit}
            variant="contained"
            disabled={isDisabled || isLoadingForm || !isDatachanged}
          >
            Save
          </Button>
          <div className={ActionDrawerStyles.main}>
            <div>
              <h4>Tool Type</h4>
              <div style={{ marginTop: 12 }}>
                <RadioGroup
                  row
                  aria-labelledby="functype-btn-group"
                  name="functype-btn-group"
                  value={functionType}
                  onChange={setFunctionType}
                >
                  <RadioLabel value="af" label="Pre-built" />
                  <RadioLabel value="cf" label="Custom" disabled />
                </RadioGroup>
              </div>
            </div>
            <div>
              <div className={ActionDrawerStyles.flex}>
                <h4>Tool</h4>
                <SelectField
                  options={functionList}
                  value={functionId}
                  onChange={(event) => {
                    const value = event?.target.value
                    const functionItem = functionList.find((item) => item.value === value)

                    if (functionItem) {
                      if (allowFunctionList.includes(functionItem.key)) {
                        setFunctionId(value)
                        setSelectedItem(functionItem)
                      } else {
                        setSubPrompt(true)
                      }
                    }
                  }}
                />
              </div>
              {selectedItem && selectedItem?.type === "" && (
                <>
                  <div className={ActionDrawerStyles.toolInput}>
                    <h5>Description</h5>
                    <div>{selectedItem.text}</div>
                  </div>
                  <div className={ActionDrawerStyles.toolInput}>
                    <h5>Sample Input</h5>
                    <BasicSnippet>{selectedItem.sample_input}</BasicSnippet>
                  </div>
                  <div className={ActionDrawerStyles.toolInput}>
                    <h5>Sample Output</h5>
                    <BasicSnippet>{selectedItem.sample_output}</BasicSnippet>
                  </div>
                </>
              )}
            </div>
            {selectedItem && selectedItem?.type === "params" && (
              <div>
                <h4>Parameters</h4>
                {selectedItem.params_json.arguments.map((arg) => (
                  <div key={arg.name} className={ActionDrawerStyles.toolInput}>
                    <>
                      {arg.name === "query" && (
                        <>
                          <h5>{TAVILY_SEARCH_PARAMS[arg.name].label}</h5>
                          <MixedTextArea
                            initialValue={paramsInput.query}
                            chipList={availableVars}
                            onBlur={(value) => {
                              setParamsInput({ ...paramsInput, query: value })
                            }}
                            onChange={handleOnChange}
                            maxTags={1}
                            mLength={200}
                            hideEndAdornment
                          />
                        </>
                      )}
                      {arg.name === "search_depth" && (
                        <>
                          <h5>{TAVILY_SEARCH_PARAMS[arg.name].label}</h5>
                          <SelectField
                            options={SEARCH_DEPTH_OPTIONS.map((option) => ({
                              label: option.charAt(0).toUpperCase() + option.slice(1),
                              value: option,
                            }))}
                            value={paramsInput.search_depth}
                            onChange={(event) => {
                              const value = event?.target.value
                              setParamsInput({ ...paramsInput, search_depth: value })
                            }}
                          />
                        </>
                      )}
                      {arg.name === "max_results" && (
                        <div className={ActionDrawerStyles.flex}>
                          <h5 style={{ margin: "2rem 0" }}>{TAVILY_SEARCH_PARAMS[arg.name].label}</h5>
                          <div style={{ alignItems: "center", display: "flex", marginRight: 12 }}>
                            <span style={{ fontSize: "0.8rem", paddingRight: 16 }}>{paramsInput.max_results}</span>
                            <DiscreteSlider
                              initialValue={paramsInput.max_results}
                              min={1}
                              max={5}
                              step={1}
                              handleSave={(value) => setParamsInput({ ...paramsInput, max_results: value })}
                            />
                          </div>
                        </div>
                      )}
                      {arg.name === "include_images" && (
                        <>
                          <h5>{TAVILY_SEARCH_PARAMS[arg.name].label}</h5>
                          <SelectField
                            options={[true, false].map((option) => ({
                              label: option ? "True" : "False",
                              value: option,
                            }))}
                            value={paramsInput.include_images}
                            onChange={(event) => {
                              const value = event?.target.value
                              setParamsInput({ ...paramsInput, include_images: value })
                            }}
                          />
                        </>
                      )}
                      {arg.name === "include_answer" && (
                        <>
                          <h5>{TAVILY_SEARCH_PARAMS[arg.name].label}</h5>
                          <SelectField
                            options={[true, false].map((option) => ({
                              label: option ? "True" : "False",
                              value: option,
                            }))}
                            value={paramsInput.include_answer}
                            onChange={(event) => {
                              const value = event?.target.value
                              setParamsInput({ ...paramsInput, include_answer: value })
                            }}
                          />
                        </>
                      )}
                      {arg.name === "include_raw_content" && (
                        <>
                          <h5>{TAVILY_SEARCH_PARAMS[arg.name].label}</h5>
                          <SelectField
                            options={[true, false].map((option) => ({
                              label: option ? "True" : "False",
                              value: option,
                            }))}
                            value={paramsInput.include_raw_content}
                            onChange={(event) => {
                              const value = event?.target.value
                              setParamsInput({ ...paramsInput, include_raw_content: value })
                            }}
                          />
                        </>
                      )}
                      {arg.name === "include_domains" && (
                        <>
                          <h5>{TAVILY_SEARCH_PARAMS[arg.name].label}</h5>
                          <Autocomplete
                            multiple
                            freeSolo
                            disableClearable
                            onChange={(_, value) => {
                              setParamsInput({ ...paramsInput, include_domains: value })
                            }}
                            options={[]}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => {
                                const { key, ...tagProps } = getTagProps({ index })
                                return <CustomChip label={option} key={key} tagProps={tagProps} />
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                placeholder={paramsInput.include_domains.length ? "" : "Optional"}
                                {...params}
                              />
                            )}
                            value={paramsInput.include_domains}
                          />
                        </>
                      )}
                      {arg.name === "exclude_domains" && (
                        <>
                          <h5>{TAVILY_SEARCH_PARAMS[arg.name].label}</h5>
                          <Autocomplete
                            multiple
                            freeSolo
                            disableClearable
                            onChange={(_, value) => {
                              setParamsInput({ ...paramsInput, exclude_domains: value })
                            }}
                            options={[]}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => {
                                const { key, ...tagProps } = getTagProps({ index })
                                return <CustomChip label={option} key={key} tagProps={tagProps} />
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                placeholder={paramsInput.exclude_domains.length ? "" : "Optional"}
                                {...params}
                              />
                            )}
                            value={paramsInput.exclude_domains}
                          />
                        </>
                      )}
                    </>
                  </div>
                ))}
              </div>
            )}
            {selectedItem && selectedItem?.type !== "params" && (
              <div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                  <h4>
                    Input
                    <BasicTooltip
                      tooltip={
                        <span>
                          {`Define the keyword or phrase to trigger tool and retrieve information/data via the internet. `}
                          <a
                            style={{ color: "#fff" }}
                            href="https://help.vextapp.com/en/articles/9268332-using-variables-in-vector-database-tools-and-function-calling"
                            target="blank"
                          >
                            Learn more.
                          </a>
                        </span>
                      }
                    />
                  </h4>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "0.2rem", cursor: "pointer" }}
                    onClick={() => {
                      if (sequence === 1) {
                        setSystemPromptInput("${payload}")
                      } else {
                        setSystemPromptInput(`\${action_${sequence - 1}_output}`)
                      }
                    }}
                  >
                    <RefreshIcon sx={{ fontSize: "0.9rem" }} />
                    <p style={{ fontSize: "0.7rem" }}>Load Default</p>
                  </div>
                </div>
                <div style={{ marginTop: 12 }}>
                  <MixedTextArea
                    className="row-6"
                    initialValue={systemPromptInput}
                    chipList={availableVars}
                    onBlur={handleOnBlur}
                    onChange={handleOnChange}
                    mLength={1000}
                  />
                </div>
              </div>
            )}
            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
              <h4>
                Output Variable
                <BasicTooltip
                  tooltip={`The result generated from this action can be referred as the following variable in any future actions.`}
                />
              </h4>
              <p>{`action_${sequence}_output`}</p>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default FunctionDrawer
