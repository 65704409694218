import React, { useContext } from "react"
import { Box, Button } from "@mui/material"
import { Bolt as BoltIcon } from "@mui/icons-material"

import { AccountContext } from "../../helper/AccountContext"
import useStripeSession from "../../helper/StripePortal"
import BackdropComponent from "./BackdropComponent"
import PricingPage from "./StripeTable"

function PlanSelection({ open }) {
  const { accountInfo, setSubPrompt, secret } = useContext(AccountContext)
  const { loading, createStripeSession } = useStripeSession()

  const handleClose = async () => {
    setSubPrompt(false)
  }

  return (
    <>
      <BackdropComponent open={open} width={"fit-content"}>
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pl: "2rem",
            pr: "2rem",
            width: accountInfo.subscriptionPlan !== "Free" ? "20rem" : "60rem",
            textAlign: "center",
            gap: "1rem",
          }}
        >
          <h3>Upgrade Plan</h3>
          {accountInfo.subscriptionPlan !== "Free" ? (
            <>
              <p style={{ justifyContent: "center" }}>Please upgrade your plan via the billing portal.</p>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "0.5rem",
                }}
              >
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  variant="contained"
                  onClick={async () => {
                    await createStripeSession("update")
                    handleClose()
                  }}
                  disabled={loading}
                  startIcon={!loading ? <BoltIcon style={{ fontSize: "1rem" }} /> : ""}
                >
                  {!loading ? "Upgrade" : "Redirecting..."}
                </Button>
              </Box>
            </>
          ) : (
            <PricingPage secret={secret} />
          )}
          {accountInfo.subscriptionPlan === "Free" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <Button onClick={handleClose}>Cancel</Button>
            </Box>
          ) : null}
        </Box>
      </BackdropComponent>
    </>
  )
}

export default PlanSelection
