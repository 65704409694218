// Essential Imports
import React, { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"

// Component Imports
import { LoadingContext } from "../helper/LoadingContext"
import { SuccessContext, ErrorContext } from "../helper/AlertContext"
import BasicTooltip from "../components/items/BasicTooltip"
import MakeSnippet from "../components/items/MakeSnippet"
import SelectField from "../components/items/SelectField"
import ConfirmationDialog from "../components/items/ConfirmationDialog"
import BackdropComponent from "../components/items/BackdropComponent"
import InputField from "../components/items/InputField"
import EmptyState from "../components/items/EmptyState"
import request from "../api/axios"

// Library Imports
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import DeleteIcon from "@mui/icons-material/Delete"
import { Stack, Skeleton } from "@mui/material"

// Stylesheet Imports
import "../styles/Apikeys.css"

export default function Apikey({ url, account }) {
  const [apiKeys, setApiKeys] = useState([])
  const [open, setOpen] = useState(false)
  const [apps, setApps] = useState([])
  const [appResults, setAppResults] = useState([])
  const [apiKeyName, setApiKeyName] = useState("")
  const [selectedApp, setSelectedApp] = useState("")
  const [apiKeyResult, setApiKeyResult] = useState("")
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [localLoading, setLocalLoading] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState({ open: false, id: null })
  const loadingContext = useContext(LoadingContext)
  const successContext = useContext(SuccessContext)
  const errorContext = useContext(ErrorContext)

  // Recursive app calling to handle pagination if needed
  const fetchApps = async () => {
    let apps = []
    let nextPage = `/app_list`

    while (nextPage) {
      try {
        const response = await request.get(nextPage)

        apps = apps.concat(response.data.results)
        nextPage = response.data.next
      } catch (error) {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
        break
      }
    }

    return apps
  }

  // Function that calls sse_key and app_list api to get key and app information
  const fetchData = async () => {
    loadingContext.setIsLoading(true)
    try {
      const apiKeysResponse = await request.get(`/sse_key`)
      const apps = await fetchApps(url)

      if (apiKeysResponse.status === 200) {
        setApiKeys(apiKeysResponse.data.text)
      } else {
        throw new Error("Error fetching API keys")
      }

      setApps(apps)
      setAppResults(apps)
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      loadingContext.setIsLoading(false)
      setIsDataLoaded(true)
    }
  }

  // Get key and app information onload
  useEffect(() => {
    fetchData()
  }, [])

  // Handles open and close of the api key generation interface
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = async (event) => {
    setOpen(false)
    setApiKeyName("")
    setSelectedApp("")
    setApiKeyResult("")
    if (event !== "cancel") {
      await fetchData()
    }
  }

  // Function that calls api key generation api
  const handleCreateApiKey = async () => {
    try {
      setLocalLoading(true)
      const response = await request.post(`/sse_key/${selectedApp}`, { name: apiKeyName })

      if (response.status === 200) {
        setApiKeyResult(response.data.text)
      } else {
        throw new Error("Error creating API key")
      }
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error)
    } finally {
      loadingContext.setIsLoading(false)
      setLocalLoading(false)
    }
  }

  // Handles confirmation component upon api key deletion
  const handleDeleteApiKey = (id) => {
    setConfirmDelete({ open: true, id })
  }

  const handleConfirmDelete = async () => {
    const id = confirmDelete.id
    setConfirmDelete({ open: false, id: null })

    loadingContext.setIsLoading(true)
    try {
      const response = await request.delete(`/sse_key/${id}`)

      if (response.status === 200) {
        successContext.setSuccess(true)
        successContext.setSuccessMsg("API Key deleted successfully")
        await fetchData()
      } else {
        throw new Error("Error deleting API key")
      }
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      loadingContext.setIsLoading(false)
    }
  }

  return (
    <div>
      {loadingContext.isLoading ? (
        <>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Skeleton variant="rounded" animation="wave" width={135} height={40} sx={{ mt: "0.3rem" }} />
          </div>
          <Stack spacing={0.5} sx={{ mt: "1rem" }}>
            <Skeleton variant="rounded" animation="wave" width={"100%"} height={50} />
            <Skeleton variant="rounded" animation="wave" width={"100%"} height={50} />
            <Skeleton variant="rounded" animation="wave" width={"100%"} height={50} />
          </Stack>
        </>
      ) : apiKeys.length > 0 ? (
        <>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" onClick={handleOpen}>
              Create API Key
            </Button>
          </div>
          <TableContainer
            component={Paper}
            style={{
              maxHeight: "100vh",
              boxShadow: "0px 2px 4px -1px rgba(61,61,61,0.6)",
              marginTop: "1rem",
            }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Assigned to...</TableCell>
                  {/* <TableCell>API Type</TableCell> */}
                  <TableCell>Created Date</TableCell>
                  <TableCell style={{ textAlign: "right" }}>Revoke</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {apiKeys.map((key, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ width: "30%" }}>{key.name}</TableCell>
                    <TableCell style={{ width: "30%" }}>{key.app.name}</TableCell>
                    {/* <TableCell style={{ width: "15%" }}>SSE & HTTP Request</TableCell> */}
                    <TableCell style={{ width: "30%" }}>
                      {new Date(parseInt(key.created)).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </TableCell>
                    <TableCell style={{ width: "5%", textAlign: "right" }}>
                      <DeleteIcon
                        className="hoverIcon"
                        style={{ fontSize: 13, cursor: "pointer" }}
                        onClick={() => handleDeleteApiKey(key.app.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* This is the confirmation component */}
          <ConfirmationDialog
            open={confirmDelete.open}
            handlePrimary={handleConfirmDelete}
            handleSecondary={() => setConfirmDelete({ open: false, id: null })}
            title="Confirm Delete"
            content="Are you sure you want to delete? This action cannot be undone."
            primaryButtonText="Delete"
            primaryButtonColor="error"
          />
        </>
      ) : isDataLoaded ? (
        <EmptyState>
          {apps.length > 0 ? (
            <Button variant="contained" onClick={handleOpen}>
              Create API Key
            </Button>
          ) : undefined}
        </EmptyState>
      ) : null}
      <BackdropComponent open={open} handleBackdropClose={handleClose} localLoading={localLoading}>
        <h3>Create API Key</h3>
        {!apiKeyResult && (
          <div className="apiContainer">
            <h4>Name</h4>
            <InputField
              value={apiKeyName}
              onChange={(newValue) => setApiKeyName(newValue)}
              mLength={30}
              endAdornment={apiKeyName.length + " / 30"}
            />
            <div className="apiOption">
              <div className="apiOptionItem">
                <h4>
                  AI Project
                  <BasicTooltip tooltip={<>Assign this API key to an AI project.</>} />
                </h4>
                <SelectField
                  options={appResults.map((app) => ({
                    value: app.id,
                    label: app.name,
                    disabled: app.api_key_available,
                  }))}
                  value={selectedApp}
                  onChange={(e) => setSelectedApp(e.target.value)}
                  disabled={false}
                />
              </div>
              {/* <div className="apiOptionItem" style={{display: "inline-block", maxWidth: "fit-content"}}>
              <h4>API Type
                <BasicTooltip
                  tooltip={
                    <>
                      Currently we only provide SSE & HTTP Request API, which is required if you'd like to integrate via SSE or HTTP Request.
                    </>
                  }
                />
              </h4>
              <Tooltip placement="top" title="Only SSE & HTTP Request API available at this time.">
                <div>
                  <SelectField
                    options={[{ value: "SSE & HTTP Request", label: "SSE & HTTP Request" }]}
                    value="SSE & HTTP Request"
                    disabled={true}
                  />
                </div>
              </Tooltip>
            </div> */}
            </div>
            <div className="apiAction">
              <Button
                variant="outlined"
                onClick={() => {
                  handleClose("cancel")
                }}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={handleCreateApiKey} disabled={!apiKeyName || !selectedApp}>
                Generate
              </Button>
            </div>
          </div>
        )}
        {apiKeyResult && (
          <div>
            <h4>Your API Key:</h4>
            <MakeSnippet title="API Key">{apiKeyResult}</MakeSnippet>
            <p style={{ textAlign: "left", fontStyle: "italic", fontSize: "0.7rem", display: "inline-block" }}>
              Important: Please make sure to save your API key in a secure location.{" "}
              <span style={{ color: "#F44336", fontWeight: 700 }}>This key will not be shown again</span> and you will
              need to generate a new one if you lose it.
            </p>
            <div className="apiAction">
              <Button
                variant="contained"
                onClick={() => {
                  handleClose()
                }}
              >
                Got it
              </Button>
            </div>
          </div>
        )}
      </BackdropComponent>
    </div>
  )
}
