// AppDirectoryItem.js

import React from "react"
import { Link, Button, IconButton } from "@mui/material"
import { Launch as LaunchIcon, MoreHorizOutlined as MoreHorizOutlinedIcon } from "@mui/icons-material"
import LocalLoadingBar from "../items/LocalLoadingBar"
import Chips from "../items/Chips"
import { ClickAwayListener } from "@mui/base"


const AppDirectoryItem = ({
  id,
  title,
  imageSrc,
  desc,
  link,
  external,
  available,
  needsAuth,
  buttonText,
  authenticateService,
  localLoading,
  setLocalLoading,
  activeDropdown,
  setActiveDropdown,
  drawer,
  toggleDrawer,
}) => {
  const handleIconClick = (e, id) => {
    e.stopPropagation()
    setActiveDropdown(activeDropdown === id ? null : id)
  }

  return (
    <div 
      className="appDirectoryItem" 
      id={id} 
      onClick={() => {
        if (!needsAuth && drawer) {
          setActiveDropdown(null)
          toggleDrawer({ id, title })
        }
      }}
      style={{
        cursor: (!needsAuth && drawer) ? "pointer" : "default"
      }}
    >
      <LocalLoadingBar localLoading={localLoading} />
      <div style={{ display: "flex", gap: "1rem" }}>
        <img style={{ width: "2rem" }} src={imageSrc} alt={title}></img>
        <div>
          <h4 style={{ marginTop: "0", marginBottom: "0.5rem" }}>{title}</h4>
          <p>{desc}</p>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {!available ? (
          <Button disabled variant="contained">
            Coming Soon
          </Button>
        ) : needsAuth && drawer ? (
          <Button
            variant="contained"
            onClick={async () => {
              setLocalLoading(true)
              await authenticateService(id)
              setLocalLoading(false)
            }}
          >
            Connect
          </Button>
       ) : external ? (
          <Button
            variant="outlined"
            startIcon={<LaunchIcon style={{ fontSize: "1rem" }} />}
            onClick={() => window.open(link, "_blank")}
          >
            {buttonText}
          </Button>
        ) : needsAuth ? (
          <Button
            variant="contained"
            onClick={async () => {
              setLocalLoading(true)
              await authenticateService(id)
              setLocalLoading(false)
            }}
          >
            Connect
          </Button>
        ) : drawer ? (
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Chips status={"enabled"} />
            <IconButton onClick={(e) => handleIconClick(e, id)} sx={{ p: 0 }}>
              <MoreHorizOutlinedIcon className="hoverIcon" />
            </IconButton>
            {activeDropdown === id && (
              <ClickAwayListener onClickAway={() => setActiveDropdown(null)}>
                <div className="option">
                  <Link
                    className="link"
                    onClick={(e) => {
                      e.stopPropagation()
                      setActiveDropdown(null)
                      toggleDrawer({ id, title })
                    }}
                  >
                    Manage {title}
                  </Link>
                  <Link
                    className="link"
                    onClick={(e) => {
                      e.stopPropagation()
                      setActiveDropdown(null)
                      authenticateService(id)
                    }}
                  >
                    Switch Account
                  </Link>
                </div>
              </ClickAwayListener>
            )}
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Chips status={"enabled"} />
            <IconButton onClick={(e) => handleIconClick(e, id)} sx={{ p: 0 }}>
              <MoreHorizOutlinedIcon className="hoverIcon" />
            </IconButton>
            {activeDropdown === id && (
              <ClickAwayListener onClickAway={() => setActiveDropdown(null)}>
                <div className="option">
                  <Link
                    className="link" 
                    onClick={(e) => {
                      e.stopPropagation()
                      setActiveDropdown(null)
                      authenticateService(id)
                    }}
                  >
                    Switch Account
                  </Link>
                </div>
              </ClickAwayListener>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default AppDirectoryItem
