// Library Imports
import React, { useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"

// Component Imports
import { ErrorContext } from "../helper/AlertContext"
import { LoadingContext } from "../helper/LoadingContext"
import AccountTabs from "../components/children/AccountTabs"
import { organizationService } from "../api/services"
import { useQuery } from "../utils"

export default function Account() {
  const loadingContext = useContext(LoadingContext)
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const navigate = useNavigate()
  const query = useQuery()

  const handleJoinOrganization = async (token) => {
    try {
      await organizationService.acceptInvitation(token)
    } catch (error) {
      setError(true)
      setErrorMsg(error.response?.data?.text)
    } finally {
      navigate("/account", { replace: true })
    }
  }

  useEffect(() => {
    const invitationToken = query.get("token")

    if (invitationToken) {
      handleJoinOrganization(invitationToken)
    }
    loadingContext.setIsLoading(false)
  }, [])

  return (
    <div>
      <AccountTabs />
    </div>
  )
}
