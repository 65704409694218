import React, { useEffect, useState } from "react"

import BasicTooltip from "../items/BasicTooltip"
import SelectField from "../items/SelectField"
import TextArea from "../items/TextArea"

const CreateMemberForm = ({ options = [], onChange = () => {} }) => {
  const [roleInput, setRoleInput] = useState("")
  const [userEmail, setUserEmail] = useState("")

  const handleOnChange = (event) => {
    setRoleInput(event.target.value)
  }

  useEffect(() => {
    onChange({ role: roleInput, email: userEmail })
  }, [roleInput, userEmail])

  return (
    <div>
      <h4 style={{ marginTop: 0 }}>
        Email(s)
        <BasicTooltip tooltip="Invite multiple members at the same time by separating email addresses with a comma." />
      </h4>
      <TextArea
        onChange={setUserEmail}
        value={userEmail}
        placeholder="e.g. hello@email.com, world@email.com"
        mLength={255}
        hideEndAdornment
      />
      <h4>Role</h4>
      <SelectField options={options} value={roleInput} onChange={handleOnChange} />
    </div>
  )
}

export default CreateMemberForm
