import React, { useState } from "react"
import { IconButton, Button, Drawer } from "@mui/material"
import { Close as CloseIcon, Delete as DeleteIcon, Tune as TuneIcon } from "@mui/icons-material"

import DiscreteSlider from "../items/Slider"
import BasicTooltip from "../items/BasicTooltip"
import SelectField from "../items/SelectField"
import TextArea from "../items/TextArea"

import FunctionColumnStyles from "./FunctionColumn.module.css"

const FunctionColumn = ({ column, topkLimit = 10, options = [], onChange = () => {}, onDelete = () => {} }) => {
  const [state, setState] = useState({ right: false })
  const [topkInput, setTopkInput] = useState(column.top_k ? column.top_k : 3)
  const [toolDescription, setToolDescription] = useState("")

  const handleDelete = (event) => {
    onDelete(+event.currentTarget?.value)
  }
  const onChangeId = (event) => {
    if (event?.target.value) {
      onChange(column.uuid, { id: event?.target.value })
    }
  }
  const handleSaveTool = () => {
    onChange(column.uuid, {
      fewshot: toolDescription,
      top_k: topkInput,
    })
  }

  const toggleDrawer = (anchor, open) => (event) => {
    event.stopPropagation()
    if (column.description) {
      setToolDescription(column.description)
    }
    if (column.top_k) {
      setTopkInput(column.top_k)
    }
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  return (
    <>
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <div style={{ flexGrow: 1 }}>
          <SelectField options={options} value={column.id} onChange={onChangeId} sx={{ width: "100%" }} />
        </div>
        <div style={{ display: "flex" }}>
          <IconButton onClick={toggleDrawer("right", true)} disabled={!column.id}>
            <TuneIcon style={{ fontSize: 16 }} />
          </IconButton>
          <IconButton onClick={handleDelete} value={column.uuid}>
            <DeleteIcon style={{ fontSize: 16 }} />
          </IconButton>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={FunctionColumnStyles.drawer}>
          <>
            <header
              style={{
                display: "flex",
                gap: "1.5rem",
                position: "relative",
                alignItems: "center",
                padding: "1.25rem 1.5rem",
                boxShadow: "rgba(61, 61, 61, 0.6) 0px 2px 4px -1px",
                zIndex: "1",
              }}
            >
              <IconButton onClick={toggleDrawer("right", false)}>
                <CloseIcon />
              </IconButton>
              <p style={{ fontSize: "1.3rem", fontWeight: 700 }}>
                {column.name ? `Data Set: ${column.name}` : column.label}
              </p>
            </header>
            <Button
              sx={{ position: "absolute", top: "1.25rem", right: "1.5rem", zIndex: 2 }}
              onClick={(e) => {
                handleSaveTool()
                toggleDrawer("right", false)(e)
              }}
              variant="outlined"
            >
              Done
            </Button>
            <div className={FunctionColumnStyles.main}>
              <div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                  <h4>
                    Tool Description
                    <BasicTooltip
                      tooltip={
                        <span>
                          {`Enter your template and variables here. To see some of the common default system prompt template, `}
                          <a
                            style={{ color: "#fff" }}
                            href="https://help.vextapp.com/en/articles/9248820-guide-to-llm-system-prompt"
                            target="blank"
                          >
                            see here.
                          </a>
                        </span>
                      }
                    />
                  </h4>
                </div>
                <div style={{ marginTop: 12 }}>
                  <TextArea
                    value={toolDescription}
                    mLength={500}
                    onChange={setToolDescription}
                    multiline
                    disabled={!column.id}
                  />
                </div>
              </div>
              {column.type === "df" && (
                <div className={FunctionColumnStyles.flex}>
                  <div style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                    <h4>
                      Top K
                      <BasicTooltip tooltip="Determine how many results the AI will refer to per query. The higher it is the more comprehensive the response will be." />
                    </h4>
                    <span style={{ fontSize: "0.8rem", marginRight: 24 }}>{topkInput}</span>
                  </div>
                  <DiscreteSlider
                    initialValue={topkInput}
                    min={1}
                    max={topkLimit}
                    step={1}
                    handleSave={setTopkInput}
                    disabled={!column.id}
                  />
                </div>
              )}
            </div>
          </>
        </div>
      </Drawer>
    </>
  )
}

export default FunctionColumn
