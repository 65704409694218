// Essential imports
import React, { useState, useContext } from "react"

// Component imports
import { ErrorContext } from "../../helper/AlertContext"

// Library imports
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack"

export default function CustomPagination({
  page,
  setPage,
  nextPageUrl,
  setNextPageUrl,
  previousPageUrl,
  setPreviousPageUrl,
  data,
  setData,
  callFunction,
}) {
  const errorContext = useContext(ErrorContext)
  const [count, setCount] = useState(data ? Math.ceil(data.count / 10) : 0)

  const fetchData = async (fetchUrl) => {
    try {
      callFunction(fetchUrl)
    } catch (error) {
      console.error("Error:", error)
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
      return []
    }
  }

  // Pagination handlers
  const handleChangePage = async (_, newPage) => {
    const result = await fetchData({ page: newPage })

    setData(result)
    setPage(newPage)
  }

  return (
    <Stack spacing={2}>
      {count > 1 && <Pagination count={count} page={page} onChange={handleChangePage} shape="rounded" />}
    </Stack>
  )
}
