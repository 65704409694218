import React from "react"
import { Link } from "react-router-dom"

import Button from "@mui/material/Button"

const NavigateButton = () => (
  <Link to="/ai-projects/create">
    <Button variant="contained">Create Project</Button>
  </Link>
)

const Empty = ({ children = <NavigateButton /> }) => (
  <div className="empty-state">
    <img src="/images/empty.svg" alt="empty" />
    <h4>Nothing here yet.</h4>
    {children}
  </div>
)

export default Empty
