// Essential Imports
import React, { useState, useEffect, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import { ClickAwayListener } from "@mui/base"

// Component Imports
import { SuccessContext, ErrorContext } from "../../helper/AlertContext"
import ConfirmationDialog from "./ConfirmationDialog"
import { appService } from "../../api/services"

// Library Imports
import { IconButton, Switch } from "@mui/material"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"

// Stylesheet Imports
import "../../styles/Items.css"

export default function AppItem({ app, getApps, toggle }) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const [isChecked, setIsChecked] = useState(toggle)
  const [localLoading, setLocalLoading] = useState(false)
  const successContext = useContext(SuccessContext)
  const errorContext = useContext(ErrorContext)
  const navigate = useNavigate()

  // Sync local isChecked state with server data when the component re-renders
  useEffect(() => {
    setIsChecked(toggle)
  }, [toggle])

  const handleToggleChange = async (event) => {
    const isActive = event.target.checked

    setLocalLoading(true)
    setIsChecked(isActive)
    try {
      await appService.updateProject(app.id, { is_close: !isActive })
      successContext.setSuccess(true)
      successContext.setSuccessMsg(`${isActive ? "Enable" : "Disable"} project.`)
    } catch (error) {
      setIsChecked(!isActive)
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      setLocalLoading(false)
    }
  }

  // Click on settings
  const handleIconClick = (event) => {
    event.stopPropagation()
    setShowDropdown(!showDropdown)
  }

  const handleItemClick = () => {
    navigate(`/ai-projects/${app.id}`)
  }

  // Open delete confirmation dialog
  const handleDeleteClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setShowDropdown(false)
    setDeleteDialogOpen(true)
  }

  // Cancel delete
  const handleCancelDelete = () => {
    setDeleteDialogOpen(false)
  }

  // Handle app deletion
  const handleDeletion = async () => {
    try {
      setLocalLoading(true)
      await appService.removeProject(app.id)
      successContext.setSuccess(true)
      successContext.setSuccessMsg("Project successfully deleted")
      setDeleteDialogOpen(false)
      getApps()
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      setLocalLoading(false)
    }
  }

  return (
    <>
      <div className="itemsContainer" id={app.id} onClick={handleItemClick}>
        <div className="appInfo">
          <h3>{app.name}</h3>
          <p>{app.description}</p>
          <p className="appDate">
            <i>
              {"Last changed: "}
              {new Date(app.updated_time).toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </i>
          </p>
        </div>
        <div style={{ position: "relative" }} className="appAction">
          <div onClick={(event) => event.stopPropagation()}>
            <Switch checked={isChecked} onChange={handleToggleChange} disabled={localLoading} />
          </div>
          <IconButton onClick={handleIconClick} sx={{ p: 0 }}>
            <MoreHorizOutlinedIcon className="hoverIcon" />
          </IconButton>
          {showDropdown && (
            <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleIconClick}>
              <div className="option">
                <Link className="link" to={`/ai-projects/${app.id}`} onClick={(event) => event.stopPropagation()}>
                  Manage
                </Link>
                <Link className="link" style={{ color: "#F44336" }} onClick={handleDeleteClick}>
                  Delete Project
                </Link>
              </div>
            </ClickAwayListener>
          )}
        </div>
      </div>
      <ConfirmationDialog
        open={deleteDialogOpen}
        handlePrimary={handleDeletion}
        handleSecondary={handleCancelDelete}
        title="Confirm Delete"
        content="Are you sure you want to delete this project? This action cannot be undone."
        primaryButtonText={localLoading ? "Deleting..." : "Delete"}
        primaryButtonColor="error"
        primaryButtonDisabled={localLoading}
      />
    </>
  )
}
