import { version } from "./version"

export const APP_VERSION = version || process.env.REACT_APP_VERSION
export const INPUT_ACCEPT_MIME_TYPE = [
  ".doc",
  ".epub",
  "image/*",
  ".msg",
  ".odt",
  ".org",
  ".ppt",
  ".rtf",
  ".rst",
  ".tsv",
  ".pptx",
  ".csv",
  ".pdf",
  ".docx",
  ".xlsx",
  ".txt",
  // ".json",
  ".md",
]
export const FILE_TYPE_MAPPING = {
  "text/plain": "TXT",
  "text/csv": "CSV",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "XLSX",
  "image/webp": "WEBP",
  "image/png": "PNG",
  "image/jpeg": "JPEG",
}
export const FRESHNESS_OPTIONS = [
  { value: 0, label: "Disabled" },
  { value: 1, label: "1 Hour", badge: "pro" },
  { value: 24, label: "24 Hours" },
  { value: 48, label: "48 Hours" },
  { value: 72, label: "72 Hours" },
]
export const MODELS = [
  // {
  //   value: "azure-gpt-3.5",
  //   label: "Azure OpenAI GPT 3.5",
  //   system: "azure-gpt-35-turbo-16k",
  //   system_ui: "azure-gpt-35-turbo",
  //   max: "2,000",
  //   type: "cs",
  //   disabled: false,
  // },
  {
    value: "azure-gpt-4o-mini",
    label: "Azure OpenAI GPT 4o Mini",
    system: "azure-gpt-4o-mini",
    max: "8,000",
    type: "cs",
    disabled: false,
  },
  {
    value: "azure-gpt-4o",
    label: "Azure OpenAI GPT 4o",
    system: "azure-gpt-4o",
    max: "4,000",
    type: "cs",
    badge: "pro",
    disabled: false,
  },
  {
    value: "azure-gpt-4",
    label: "Azure OpenAI GPT 4",
    system: "azure-gpt-4-turbo",
    max: "2,000",
    type: "cs",
    badge: "pro",
    disabled: false,
  },
  // {
  //   value: "gpt-4o",
  //   label: "OpenAI GPT 4o",
  //   system: "gpt-4o",
  //   max: "2,000",
  //   type: "cs",
  //   badge: "pro",
  //   disabled: false,
  // },
  // { value: "gpt-3.5", label: "OpenAI GPT 3.5", system: "gpt-3.5-turbo-16k", disabled: false },
  // { value: "gpt-4", label: "OpenAI GPT 4", system: "gpt-4", disabled: false },
  {
    value: "claude-instant",
    label: "Anthropic Claude Instant",
    system: "anthropic.claude-instant-v1",
    max: "4,000",
    type: "cs",
    disabled: false,
  },
  {
    value: "claude-v2",
    label: "Anthropic Claude 2",
    system: "anthropic.claude-v2",
    max: "2,000",
    type: "cs",
    badge: "pro",
    disabled: false,
  },
  {
    value: "claude-v3-haiku",
    label: "Anthropic Claude 3 Haiku",
    system: "anthropic.claude-3-haiku-20240307-v1:0",
    system_ui: "anthropic.claude-3-haiku-20240307-v1",
    max: "4,000",
    type: "cs",
    disabled: false,
  },
  {
    value: "claude-v3-opus",
    label: "Anthropic Claude 3 Opus",
    system: "anthropic.claude-3-opus-20240229-v1:0",
    system_ui: "anthropic.claude-3-opus-20240229-v1",
    max: "2,000",
    type: "cs",
    badge: "pro",
    disabled: false,
  },
  {
    value: "claude-v3-sonnet",
    label: "Anthropic Claude 3 Sonnet",
    system: "anthropic.claude-3-sonnet-20240229-v1:0",
    system_ui: "anthropic.claude-3-sonnet-20240229-v1",
    max: "4,000",
    type: "cs",
    badge: "pro",
    disabled: false,
  },
  {
    value: "gemini-pro",
    label: "Google Gemini 1.0 Pro",
    system: "gemini-pro",
    system_ui: "gemini-pro-1",
    type: "cs",
    max: "4,000",
    disabled: false,
  },
  {
    value: "gemini-pro-1.5",
    label: "Google Gemini 1.5 Pro",
    system: "gemini-1.5-pro-latest",
    system_ui: "gemini-pro-1.5",
    type: "cs",
    max: "4,000",
    badge: "pro",
    disabled: false,
  },
  {
    value: "gemini-flash-1.5",
    label: "Google Gemini 1.5 Flash",
    system: "gemini-1.5-flash-latest",
    system_ui: "gemini-flash-1.5",
    type: "cs",
    max: "4,000",
    disabled: false,
  },
  {
    value: "command-light",
    label: "Cohere Command Light",
    system: "cohere.command-light-text-v14",
    max: "4,000",
    type: "cs",
    disabled: false,
  },
  {
    value: "command",
    label: "Cohere Command",
    system: "cohere.command-text-v14",
    type: "cs",
    max: "4,000",
    disabled: false,
  },
  {
    value: "command-r",
    label: "Cohere Command R",
    system: "cohere.command-r-v1:0",
    type: "cs",
    max: "4,000",
    disabled: true,
  },
  {
    value: "command-r-plus",
    label: "Cohere Command R+",
    system: "cohere.command-r-plus-v1:0",
    type: "cs",
    max: "4,000",
    disabled: true,
  },
  {
    value: "llama3-8b",
    label: "Meta Llama 3 8B",
    system: "meta.llama3-8b-instruct-v1:0",
    system_ui: "meta.llama3-8b-instruct-v1",
    type: "llama3",
    max: "2,000",
    disabled: false,
  },
  {
    value: "llama3-70b",
    label: "Meta Llama 3 70B",
    system: "meta.llama3-70b-instruct-v1:0",
    system_ui: "meta.llama3-70b-instruct-v1",
    type: "llama3",
    max: "2,000",
    disabled: false,
  },
  {
    value: "llama3.1-8b",
    label: "Meta Llama 3.1 8B",
    system: "meta.llama3-1-8b-instruct-v1:0",
    system_ui: "meta.llama3-1-8b-instruct-v1",
    type: "llama3",
    max: "2,000",
    disabled: false,
  },
  {
    value: "llama3.1-70b",
    label: "Meta Llama 3.1 70B",
    system: "meta.llama3-1-70b-instruct-v1:0",
    system_ui: "meta.llama3-1-70b-instruct-v1",
    type: "llama3",
    max: "2,000",
    disabled: false,
  },
  {
    value: "mistral-7b",
    label: "Mistral 7B",
    system: "mistral.mistral-7b-instruct-v0:2",
    system_ui: "mistral.mistral-7b-instruct-v0",
    type: "os",
    max: "8,000",
    disabled: false,
  },
  {
    value: "mixtral-8x7b",
    label: "Mixtral 8x7B",
    system: "mistral.mixtral-8x7b-instruct-v0:1",
    system_ui: "mistral.mixtral-8x7b-instruct-v0",
    max: "4,000",
    type: "os",
    disabled: false,
  },
  {
    value: "mistral-large",
    label: "Mistral Large",
    system: "mistral.mistral-large-2402-v1:0",
    system_ui: "mistral.mistral-large-2402-v1",
    max: "2,000",
    type: "cs",
    badge: "pro",
    disabled: false,
  },
]
export const USER_CHECKLIST = [
  {
    key: "create_app",
    text: "Create a new project",
    link: "/ai-projects/create",
    isActive: false,
    isCompleted: false,
  },
  {
    key: "playground",
    text: "Test your AI in the Vext Playground",
    link: "/ai-projects",
    isActive: false,
    isCompleted: false,
  },
  {
    key: "tune_config",
    text: "Fine-tune AI configuration",
    link: "/ai-projects",
    isActive: false,
    isCompleted: false,
  },
  {
    key: "view_log",
    text: "View history",
    link: "/logs",
    isActive: false,
    isCompleted: false,
  },
]
export const DATA_SOURCES = {
  text: "Plain Text",
  file: "File",
  csv: "File",
  cp: "Webpage Crawler",
  crawler_webpage: "Webpage Crawler",
  crawler_website: "Website Crawler",
  google_docs: "Google Docs",
  google_sheets: "Google Sheets",
  xlsx: "File",
  docx: "File",
  json: "File",
  xml: "File",
}
export const ALLOW_SYNC_SOURCES = ["cp", "cs", "media", "confluence", "google", "notion", "sharepoint"]
export const FUNCTION_DETAILS = {
  llm: {
    name: "LLM",
    description: "",
    sample_input: "",
    sample_output: "",
  },
  "code interpreter": {
    name: "Code Interpreter",
    description: "",
    sample_input: "",
    sample_output: "",
  },
  "tavily search": {
    name: "Tavily Search",
    description: "",
    sample_input: "",
    sample_output: "",
    type: "params",
  },
  "basic math": {
    name: "Basic Math",
    description: "Performs basic mathematical operations.",
    sample_input: "192+204/54^6",
    sample_output: "192.0000000082275",
  },
  wolframalpha: {
    name: "WolframAlpha",
    description: "Performs complex mathematical operations, supports natural language input.",
    sample_input: "Integrate[Sin[xy],x,y]",
    sample_output:
      "Assumption: integral integral sin(x y) dx dy = -Ci(x y) + c_1 x + c_2 Answer: integral integral sin(x y) dx dy = -Ci(x y) + c_1 x + c_2",
  },
  "weather map": {
    name: "OpenWeather",
    description: "Get weather forecast for a specific location.",
    sample_input: "Mountain View, USA",
    sample_output:
      "In Mountain View, CA, the current weather is as follows: Detailed status: few clouds Wind speed: 12.35 m/s, direction: 230° Humidity: 54% Temperature: Current: -2.95°C High: -2.95°C Low: -5.06°C Feels like: -9.95°C Heat index: None Cloud cover: 12%",
  },
  pubmed: {
    name: "PubMed",
    description: "Search for biomedical literature in the PubMed database.",
    sample_input: "Artificial intelligence",
    sample_output:
      "Published: -- Title: [Value of CT Quantitative Parameters in Prediction of Pathological Types of Lung Ground Glass Nodules]. Copyright Information: Summary:: BACKGROUND: The pathological types of lung ground glass nodules (GGNs) show great significance to the clinical treatment...",
  },
  "stack exchange": {
    name: "Stack Exchange",
    description: "",
    sample_input: "",
    sample_output: "",
  },
  arxiv: {
    name: "ArXiv",
    description: "Search for academic papers in the ArXiv preprint repository.",
    sample_input: "Artificial Intelligence",
    sample_output:
      "Published: 2023-04-06 Title: The Governance of Physical Artificial Intelligence Authors: Yingbo Li, Anamaria-Beatrice Spulber, Yucong Duan Summary: Physical artificial intelligence can prove to be one of the most important challenges of the artificial intelligence...",
  },
  wikipedia: {
    name: "Wikipedia",
    description: "Retrieve detailed content for a Wikipedia article.",
    sample_input: "Artificial Intelligence",
    sample_output:
      "Page: Artificial intelligence Summary: Artificial intelligence (AI) is the intelligence of machines or software, as opposed to the intelligence of living beings, primarily of humans. It is a field of study in computer science that develops and studies intelligent machines...",
  },
  "duckduckgo search": {
    name: "DuckDuckGo Search",
    description: "Search the web using the DuckDuckGo search engine.",
    sample_input: "Artificial Intelligence",
    sample_output:
      "Learn about artificial intelligence (AI), the ability of computers to perform tasks commonly associated with intelligent beings. Explore the components of intelligence, such as learning, reasoning, problem solving, perception, and language, and the applications and challenges of AI...",
  },
  "google search": {
    name: "Google Search",
    description: "Search for any keyword using the Google search engine and return relevant web results.",
    sample_input: "Artificial Intelligence",
    sample_output:
      "The traditional goals of AI research include reasoning, knowledge representation, planning, learning, natural language processing, perception, and support for ... AI automates repetitive learning and discovery through data. Instead of automating manual tasks, AI performs frequent, high-volume...",
  },
  dataframe: {
    name: "Vector Database",
    description: "",
    sample_input: "",
    sample_output: "",
  },
}
export const TAVILY_SEARCH_PARAMS = {
  query: {
    label: "Query",
  },
  search_depth: {
    label: "Search Depth",
  },
  max_results: {
    label: "Max Results",
  },
  include_images: {
    label: "Include Images",
  },
  include_answer: {
    label: "Include Answer",
  },
  include_raw_content: {
    label: "Include Raw Content",
  },
  include_domains: {
    label: "Include Domains",
  },
  exclude_domains: {
    label: "Exclude Domains",
  },
}
export const ROLES = ["admin", "editor", "read"]
export const CUSTOM_ERR_MSG = {
  general_forbidden: "You do not have access to perform this action, contact your admin for more info.",
  login_not_activated: "The account is not activated yet. Please check your email for the activation link.",
  login_invalid_cred: "Invalid email or password. Please try again.",
  signup_google_exist: "Account already exists, please login with Google.",
  signup_email_exist: "Account already exists, please login with your email.",
  activation_incorrect_userid: "Invalid session. Please click on the link via the activation email.",
  activation_already_activated: "This account has already been activated.",
  reset_not_activated: "The account is not activated yet. Please check your email for the activation link.",
  org_invite_more_than_one: "The invited user has reached the organization join limit.",
  account_get_role_failed: "You do not have access to view organization member list, contact your admin for more info.",
  undefined: "Unexpected error.",
}
export const SYSTEM_PROMPT = {
  cs_rag: {
    file: "https://statics.vextapp.com/cs.txt",
  },
  os_rag: {
    file: "https://statics.vextapp.com/os.txt",
  },
  llama3_rag: {
    file: "https://statics.vextapp.com/llama3.txt",
  },
  cs_agent: {
    file: "https://statics.vextapp.com/cs_agent.txt",
  },
  os_agent: {
    file: "https://statics.vextapp.com/os_agent.txt",
  },
}
export const DEFAULT_TEMPERATURE = 0.7
export const DEFAULT_TOP_P = 1
export const DEFAULT_TOP_K = 40
