import {
  AutoAwesome as AutoAwesomeIcon,
  Code as CodeIcon,
  Storage as StorageIcon,
  Construction as ConstructionIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
  AltRoute as AltRouteIcon,
  Psychology as PsychologyIcon,
} from "@mui/icons-material"

export const EDGE_LENGTH = 160

export const NODE_CONFIG = {
  type: "component",
  data: {
    icon: AutoAwesomeIcon,
    label: "LLM",
    name: "lm",
    description: "Deploy an LLM",
  },
}

export const NODE_DATASET = {
  type: "component",
  data: {
    icon: StorageIcon,
    label: "Vector Database",
    name: "df",
    description: "Find relevant info based on input",
  },
}

export const NODE_FUNCTION = {
  type: "component",
  data: {
    icon: ConstructionIcon,
    label: "Tools",
    name: "af",
    description: "Execute tasks with tools",
  },
}

export const NODE_SMART_FUNCTION = {
  type: "component",
  data: {
    icon: PsychologyIcon,
    label: "Agent",
    name: "sf",
    description: "Execute tasks from a toolkit",
  },
}

export const NODE_QUERY = {
  id: "nstart",
  type: "component",
  data: {
    icon: ArrowForwardIcon,
    label: "Input",
    name: "query",
    description: "An event that starts the flow",
  },
}

export const NODE_OUTPUT = {
  type: "component",
  data: {
    icon: ArrowBackIcon,
    label: "Output",
    name: "output",
    description: "Output the result to a destination",
  },
}

export const NODE_CLASSIFIER = {
  type: "component",
  data: {
    icon: AltRouteIcon,
    label: "Smart Routing",
    name: "cls",
    description: "",
  },
}

export const NODE_CODE = {
  type: "component",
  data: {
    icon: CodeIcon,
    label: "Code",
    name: "code",
    description: "",
  },
}
