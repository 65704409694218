// Essential Imports
import React, { useState, useEffect, useContext } from "react"
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom"

// Component Imports
import { AuthContext } from "./helper/AuthProvider"
import { ErrorProvider, SuccessProvider, WarningProvider } from "./helper/AlertContext"
import { LoadingContext } from "./helper/LoadingContext"
import { TitleContext } from "./helper/TitleContext"
import { AccountContext } from "./helper/AccountContext"
import PrivateRouteWrapper from "./helper/PrivateRoute"
import Theme from "./components/template/Theme"
import Navbar from "./components/sections/Navbar"
import Header from "./components/sections/Header"
import Footer from "./components/sections/Footer"
import Error from "./components/items/Error"
import Success from "./components/items/Success"
import Warning from "./components/items/Warning"
import IntercomApp from "./components/items/Intercom"
import GlobalLoadingBar from "./components/items/GlobalLoadingBar"
import OAuthHandler from "./pages/OAuthHandler"
import Signup from "./pages/Signup"
import SignupSuccess from "./pages/SignupSuccess"
import Login from "./pages/Login"
import ActivateAccount from "./pages/ActivateAccount"
import ResetPassword from "./pages/ResetPassword"
import Dashboard from "./pages/Dashboard"
import Data from "./pages/Data"
import NotFound from "./pages/NotFound"
import Apps from "./pages/Apps"
import Apikeys from "./pages/Apikeys"
import AppDirectory from "./pages/AppDirectory"
import Logs from "./pages/Logs"
import Account from "./pages/Account"
// import CreateMain from "./pages/CreateMain" // TODO: remove deprecated component
import ProjectDetail from "./pages/ProjectDetail"
import DataDetail from "./pages/DataDetail"
import SiteDetail from "./pages/SiteDetail"
import DataSourceDetail from "./pages/DataSourceDetail"
import OAuthRedirect from "./pages/OAuthRedirect"
import PlanSelection from "./components/items/PlanSelection"
import { accountService } from "./api/services"

// Library Imports
import { ThemeProvider } from "@mui/material/styles"

// Stylesheet Imports
import "./styles/app.css"
import "./styles/font.css"

// Dynamic variables
const url = `${process.env.REACT_APP_API_ROOT}/api/v2`
const appTitle = process.env.REACT_APP_TITLE
const blockedPaths = ["/login", "/signup", "/forgot-password", "/set-password", "/activate"]

function App() {
  const loadingContext = useContext(LoadingContext)
  const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")) || {})
  const [title, setTitle] = useState("")
  const { fetchAccountStatus, isAuthenticated, setIsAuthenticated, subPrompt, setSubPrompt, secret } =
    useContext(AccountContext)
  const navigate = useNavigate()
  const location = useLocation()
  const isCreateRoute = location.pathname.startsWith("/create")
  const [account, setAccount] = useState({})
  const [prevSearchParams, setPrevSearchParams] = useState("")

  // Set title
  useEffect(() => {
    document.title = appTitle
  }, [appTitle])

  // Effect to set the page title on route changes
  useEffect(() => {
    const pathRequiresDynamicTitle = location.pathname.startsWith("/data/")
    if (!pathRequiresDynamicTitle) {
      setTitle("")
    }
  }, [location, setTitle])

  // Effect to handle redirects based on authentication status
  useEffect(() => {
    if (isAuthenticated) {
      sessionStorage.setItem("currentRoute", location.pathname)
    }
  }, [isAuthenticated, location.pathname, navigate])

  // Effect to check user authentication status every 30 seconds
  useEffect(() => {
    function checkAuth() {
      if (document.cookie.includes("userLoggedIn=true")) {
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
      }
    }
    checkAuth()
    const intervalId = setInterval(checkAuth, 30000)
    return () => clearInterval(intervalId)
  }, [location])

  // To keep query string while browsering is-blocked pages
  useEffect(() => {
    const hasLoginToken = document.cookie.includes("userLoggedIn=true")
    const isBlocked = blockedPaths.some((blockedPath) => location.pathname.startsWith(blockedPath))

    if (isBlocked) {
      if (location.search) {
        setPrevSearchParams(location.search)
      } else if (prevSearchParams) {
        navigate(`${location.pathname}${prevSearchParams}`, { replace: true })
      }
      if (hasLoginToken) {
        const prevPath = sessionStorage.getItem("currentRoute") || "/dashboard"
        navigate(prevPath, { replace: true })
      }
    } else {
      if (Object.keys(account).length === 0) {
        fetchAccountStatus((account) => {
          setAccount(account)
        })
      }
    }
  }, [location.pathname])

  // Function to handle user logout
  const logOut = async () => {
    try {
      await accountService.logout()
      localStorage.removeItem("profile")
      localStorage.removeItem("vext_enterprise")
      localStorage.removeItem("orgId")
      sessionStorage.clear()
      setIsAuthenticated(false)

      document.cookie = "userLoggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
      navigate("/login")
    } catch (error) {
      // console.error(error.message);
    }
  }
  const getHomePagePath = () => {
    if (document.cookie.includes("userLoggedIn=true")) {
      return "/dashboard"
    }
    return "/login"
  }

  // Array of routes
  // Each route object includes a path, component, and page title
  const routes = [
    { path: "/dashboard", component: Dashboard, pageTitle: "Dashboard" },
    { path: "/data", component: Data, pageTitle: "Data Sets" },
    { path: "/ai-projects", component: Apps, pageTitle: "AI Projects" },
    { path: "/logs", component: Logs, pageTitle: "Logs" },
    { path: "/account", component: Account, pageTitle: "My Account" },
    { path: "/api-keys", component: Apikeys, pageTitle: "API Keys" },
    { path: "/app-directory", component: AppDirectory, pageTitle: "App Directory" },
    { path: "/ai-projects/create", component: ProjectDetail, pageTitle: "" },
    { path: "/ai-projects/:app_id", component: ProjectDetail, pageTitle: "" },
    {
      path: "/data/:dataframeId",
      component: DataDetail,
      pageTitle: "",
    },
    {
      path: "/data/:dataframeId/:sourcePath",
      component: DataSourceDetail,
      pageTitle: "",
    },
    {
      path: "/data/:dataframeId/:sourcePath/:siteDetail",
      component: SiteDetail,
      pageTitle: "",
    },
  ]

  const intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    name: profile ? profile.firstName + " " + profile.lastName : "",
    email: profile ? profile.email : "",
    plan_type: account.subscriptionPlan,
  }

  return (
    // Provide the theme, authentication status, loading status, title, success and error contexts to the entire app
    <ThemeProvider theme={Theme}>
      <SuccessProvider>
        <WarningProvider>
          <ErrorProvider>
            <AuthContext.Provider value={{ isAuthenticated, setAuth: setIsAuthenticated }}>
              <TitleContext.Provider value={{ title, setTitle }}>
                <PlanSelection open={subPrompt} />
                <IntercomApp {...intercomSettings} />
                <Error />
                <Success />
                <Warning />
                <GlobalLoadingBar />
                <Routes>
                  {routes.map(({ path, component: Component, pageTitle }) => (
                    // For each route in the array, create a Route. If the user is authenticated, wrap it in a PrivateRouteWrapper
                    <Route
                      key={path}
                      path={path}
                      element={
                        <PrivateRouteWrapper>
                          {/* All Frame */}
                          <div className="allFrame">
                            {/* Nav Frame */}
                            {!isCreateRoute && (
                              <div className="navFrame">
                                <Navbar />
                              </div>
                            )}
                            {/* Main Frame */}
                            <div className="mainFrame">
                              <TitleContext.Consumer>
                                {({ title }) => <Header logOut={logOut} pageTitle={title || pageTitle} />}
                              </TitleContext.Consumer>
                              {/* Container */}
                              <div className="container">
                                <Component
                                  profile={profile}
                                  url={url}
                                  account={account}
                                  subPrompt={subPrompt}
                                  setSubPrompt={setSubPrompt}
                                />
                              </div>
                              {!isCreateRoute && (
                                // Footer
                                <div className="footer">
                                  <Footer />
                                </div>
                              )}
                            </div>
                          </div>
                        </PrivateRouteWrapper>
                      }
                    />
                  ))}
                  {/* Redirecting based on authentication status */}
                  <Route path="/create" element={<Navigate to="/ai-projects/create" replace />} />
                  <Route path="/oauth/:appName" element={<OAuthHandler isAuthenticated={isAuthenticated} />} />
                  <Route path="/oauth-redirect" element={<OAuthRedirect />} />
                  <Route path="/signup" element={<Signup setProfile={setProfile} url={url} />} />
                  <Route path="/signup-success" element={<SignupSuccess />} />
                  <Route path="/login" element={<Login setProfile={setProfile} url={url} />} />
                  <Route path="/" element={<Navigate to={getHomePagePath()} replace />} />
                  <Route path="/forgot-password" element={<ResetPassword />} />
                  <Route path="/forgot-password/:userId" element={<ResetPassword />} />
                  <Route path="/activate/:userId" element={<ActivateAccount setProfile={setProfile} />} />
                  {/* Fallback route for non-existent routes */}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </TitleContext.Provider>
            </AuthContext.Provider>
          </ErrorProvider>
        </WarningProvider>
      </SuccessProvider>
    </ThemeProvider>
  )
}
export default App
