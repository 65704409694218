import React, { useContext, useEffect, useState } from "react"
import { Button, IconButton, Tooltip, Stack, Skeleton } from "@mui/material"
import { Delete as DeleteIcon, WarningAmber as WarningAmberIcon } from "@mui/icons-material"

import BasicTooltip from "../../items/BasicTooltip"
import LocalLoadingBar from "../../items/LocalLoadingBar"
import DiscreteSlider from "../../items/Slider"
import SelectField from "../../items/SelectField"
import TextArea from "../../items/TextArea"
import VariableSection from "../../items/VariableSection"
import { AccountContext } from "../../../helper/AccountContext"
import { MODELS } from "../../../utils/constants"

import ActionDrawerStyles from "./ActionDrawer.module.css"

const MINIMUM_ROUTE = 2
const MAXIMUM_ROUTE = 4

const ClassifierDrawer = ({ customVariables, formData, sequence, onSubmit, isLoadingForm, setUnsaved }) => {
  const [allowModels, setAllowModels] = useState([])
  const [systemPromptInput, setSystemPromptInput] = useState("")
  const [inputCursorPosition, setInputCursorPosition] = useState({ start: -1, end: -1 })
  const [temperatureInput, setTemperatureInput] = useState(0.7)
  const [modelInput, setModelInput] = useState("")
  const [routeList, setRouteList] = useState([
    { uuid: 0, description: "" },
    { uuid: 1, description: "" },
  ])
  const [isDatachanged, setIsDataChanged] = useState(true)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { fetchAccountStatus, setSubPrompt } = useContext(AccountContext)

  const addLabel = (value) => {
    if (inputCursorPosition.start + inputCursorPosition.end >= 0) {
      setSystemPromptInput(
        systemPromptInput.substring(0, inputCursorPosition.start) +
          "${" +
          value +
          "}" +
          systemPromptInput.substring(inputCursorPosition.end, systemPromptInput.length),
      )
    }
  }
  const functionAction = {
    add: () => {
      setRouteList((prevValue) => [...prevValue, { uuid: new Date().getTime(), description: "" }])
    },
    edit: (itemUuid, value) => {
      setRouteList((prevValue) =>
        prevValue.map((item) => (item.uuid === itemUuid ? { ...item, description: value } : item)),
      )
    },
    remove: (itemUuid) => {
      setRouteList((prevValue) => prevValue.filter((item) => item.uuid !== itemUuid))
    },
  }
  const handleChangeBehaviorInput = (value) => {
    setSystemPromptInput(value)
  }
  const handleSelectBehaviorInput = (selectionStart, selectionEnd) => {
    setInputCursorPosition({ start: selectionStart, end: selectionEnd })
  }
  const handleOnSubmit = () => {
    if (isDatachanged) {
      let payload = { componentId: formData.componentId, systemPrompt: systemPromptInput }

      onSubmit(payload)
    } else {
      onSubmit()
    }
  }
  const handleChangeModel = (event) => {
    const targetModel = MODELS.find((model) => model.value === event?.target.value) || {}

    if (targetModel.value) {
      if (allowModels.includes(targetModel.value)) {
        setModelInput(targetModel.value)
      } else {
        setSubPrompt(true)
      }
    }
  }

  useEffect(() => {
    if (allowModels?.length) {
      setIsLoading(false)
    }
  }, [allowModels])

  useEffect(() => {
    if (formData.id) {
      const targetModel = MODELS.find((model) => model.system === formData.model) || {}

      setTemperatureInput(formData.temperature)
      setModelInput(targetModel.value)
      setSystemPromptInput(formData.systemPrompt)
    }
  }, [formData])

  useEffect(() => {
    setUnsaved(isDatachanged)
  }, [isDatachanged])

  useEffect(() => {
    fetchAccountStatus((account) => {
      setAllowModels(account.allowModels)
    })
  }, [])

  return (
    <>
      <section style={{ position: "relative" }}>
        <LocalLoadingBar localLoading={isLoading || isLoadingForm} />
      </section>
      {isLoading ? (
        <Stack spacing={2} sx={{ padding: "1.5rem 2rem" }}>
          <Skeleton variant="rounded" animation="wave" height={80} />
          <Skeleton variant="rounded" animation="wave" height={70} />
          <Skeleton variant="rounded" animation="wave" height={65} />
          <Skeleton variant="rounded" animation="wave" height={400} />
        </Stack>
      ) : (
        <>
          <Button
            sx={{ position: "absolute", top: "1.25rem", right: "1.5rem", zIndex: 2 }}
            onClick={handleOnSubmit}
            variant="contained"
            disabled={isDisabled || isLoadingForm || !isDatachanged}
          >
            Save
          </Button>
          <div className={ActionDrawerStyles.main}>
            <div className={ActionDrawerStyles.flex}>
              <h4>
                Decision Model
                <BasicTooltip tooltip="Which LLM model to deploy to operate your LLM pipeline." />
              </h4>
              <div>
                <div style={{ flex: 1 }}>
                  <SelectField
                    options={MODELS}
                    value={modelInput}
                    onChange={handleChangeModel}
                    disabled={isLoadingForm}
                  />
                </div>
              </div>
            </div>
            <div className={ActionDrawerStyles.flex}>
              <div style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
                <h4>
                  Temperature
                  <BasicTooltip tooltip="Higher values will make the output more random, while lower values will make it more focused and deterministic." />
                </h4>
                <span style={{ display: "flex", fontSize: "0.8rem", paddingRight: 16 }}>
                  {temperatureInput > 1 && (
                    <Tooltip
                      title={`The outcome will become increasingly unpredictable if set beyond "1", and exceptionally difficult to predict if set beyond "1.8".`}
                    >
                      <WarningAmberIcon
                        color="warning"
                        sx={{
                          cursor: "pointer",
                          fontSize: "0.8rem",
                          paddingRight: 0.5,
                        }}
                      />
                    </Tooltip>
                  )}
                  {temperatureInput}
                </span>
              </div>
              <DiscreteSlider
                initialValue={temperatureInput}
                min={0}
                max={2}
                step={0.01}
                handleSave={setTemperatureInput}
              />
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <h4>
                  Input
                  <BasicTooltip
                    tooltip={
                      <span>
                        {`Enter your template and variables here. To see some of the common default system prompt template, `}
                        <a
                          style={{ color: "#fff" }}
                          href="https://help.vextapp.com/en/articles/9248820-guide-to-llm-system-prompt"
                          target="blank"
                        >
                          see here.
                        </a>
                      </span>
                    }
                  />
                </h4>
              </div>
              <div style={{ marginTop: 12 }}>
                <TextArea
                  onChange={handleChangeBehaviorInput}
                  value={systemPromptInput}
                  mLength={1000}
                  rows={6}
                  inputComponent="textarea"
                  hideEndAdornment
                />
              </div>
            </div>
            <div>
              <h4 style={{ paddingBottom: "1rem" }}>
                Routes ({routeList.length}/{MAXIMUM_ROUTE})
              </h4>
              <div>
                {routeList.map((route, index) => (
                  <div key={route.uuid} style={{ marginBottom: 12 }}>
                    <h4 style={{ fontSize: "0.8rem" }}>Route {String.fromCharCode(index + 65)}</h4>
                    <div style={{ display: "flex", marginTop: 8 }}>
                      <TextArea
                        value={route.description}
                        mLength={500}
                        onChange={(value) => functionAction.edit(route.uuid, value)}
                        onSelect={handleSelectBehaviorInput}
                      />
                      <IconButton
                        onClick={() => functionAction.remove(route.uuid)}
                        disabled={routeList.length <= MINIMUM_ROUTE}
                      >
                        <DeleteIcon style={{ fontSize: 16 }} />
                      </IconButton>
                    </div>
                  </div>
                ))}
                <div className={ActionDrawerStyles.variableContainer}>
                  <span>
                    Available Variables
                    <BasicTooltip
                      tooltip={
                        <span>
                          {`These are the variables from your endpoint or results generated from other actions. `}
                          <a
                            style={{ color: "#fff" }}
                            href="https://help.vextapp.com/en/articles/9248817-what-is-variables-in-system-prompt"
                            target="blank"
                          >
                            Learn more.
                          </a>
                        </span>
                      }
                    />
                    :
                  </span>
                  <VariableSection variables={customVariables} sequence={sequence} handleClickChip={addLabel} />
                </div>
                <Button
                  onClick={functionAction.add}
                  className={ActionDrawerStyles.add}
                  style={{ marginTop: 8 }}
                  disabled={routeList.length >= MAXIMUM_ROUTE}
                >
                  + Add Route
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ClassifierDrawer
