// Library Imports
import React, { useContext, useState, useEffect } from "react"
import { Drawer, IconButton, Skeleton } from "@mui/material"
import { Close as CloseIcon, FindInPageOutlined as FindInPageOutlinedIcon } from "@mui/icons-material"

// Component Imports
import { ErrorContext } from "../../helper/AlertContext"
import BasicTooltip from "../items/BasicTooltip"
import JsonSnippet from "../items/JsonSnippet"
import PythonSnippet from "../items/PythonSnippet"

import { FUNCTION_DETAILS, MODELS } from "../../utils/constants"
import { formatJsonString } from "../../utils"
import { logService } from "../../api/services"

import {
  NODE_CODE,
  NODE_CONFIG,
  NODE_DATASET,
  NODE_FUNCTION,
  NODE_SMART_FUNCTION,
  NODE_QUERY,
  NODE_OUTPUT,
} from "../template/actionflow.static"

// Style Imports
import LogFragmentsStyles from "../../styles/LogFragments.module.css"

const nodeTypeMapping = {
  code: NODE_CODE,
  lm: NODE_CONFIG,
  df: NODE_DATASET,
  af: NODE_FUNCTION,
  sf: NODE_SMART_FUNCTION,
  query: NODE_QUERY,
  output: NODE_OUTPUT,
}

const CardHeader = ({ nodeType }) => {
  const NodeIcon = nodeTypeMapping[nodeType].data.icon
  const title = nodeTypeMapping[nodeType].data.label
  return (
    <div className={LogFragmentsStyles.logTitle}>
      <NodeIcon style={{ fontSize: 24 }} />
      <h4>{title}</h4>
    </div>
  )
}

const DataSection = ({ type, language, label, content = "" }) => {
  const renderContent = () => {
    if (type === "tavily") {
      const parsedContent = JSON.parse(content)

      if (typeof parsedContent === "string") {
        return parsedContent
      }
      return (
        <div>
          {parsedContent.map((item, index) => (
            <div key={index}>{<JsonSnippet>{formatJsonString(JSON.stringify(item))}</JsonSnippet>}</div>
          ))}
        </div>
      )
    }
    if (content && (type === "query" || type === "output" || type === "json")) {
      return <JsonSnippet>{formatJsonString(content)}</JsonSnippet>
    }
    if (content && type === "python") {
      return <PythonSnippet>{formatJsonString(content)}</PythonSnippet>
    }
    if (content && typeof content === "object") {
      return (
        <div>
          {Object.entries(content).map(([key, value], index) => (
            <span key={index} className={LogFragmentsStyles.dataItem}>
              {`${key}:`}
              <br />
              {`${value}`}
            </span>
          ))}
        </div>
      )
    }
    return content
  }

  return (
    <div>
      <h5>{type === "tavily" ? "Tavily Search" : label}</h5>
      <div className={`${LogFragmentsStyles.logContent}`}>
        <div>{renderContent()}</div>
      </div>
    </div>
  )
}

export default function LogFragments({ createTime, logId, creditCount, input, output }) {
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const [state, setState] = useState({ right: false })
  const [isLocalLoading, setIsLocalLoading] = useState(false)
  const [firstInput, setFirstInput] = useState("")
  const [lastOutput, setLastOutput] = useState("")
  const [components, setComponents] = useState([])

  const fetchLogDetail = async (logId) => {
    setIsLocalLoading(true)
    try {
      const { data } = await logService.getLogDetail(logId)
      setFirstInput(data.input)
      setComponents(data.component_response)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }

  const mapModelName = (modelName) => {
    const targetItem = MODELS.find((m) => m.system === modelName)

    if (targetItem) {
      return targetItem.label
    }
    if (modelName === "hf_sm") {
      return "Sagemaker Custom Model"
    }
    return modelName
  }

  const parseResults = (inputString) => {
    const results = {}

    if (!inputString) {
      return {}
    }
    const parts = inputString.split(/(?:^|\n)result_/)

    parts.forEach((part) => {
      const match = part.match(/^(\d+):([\s\S]*)/)
      if (match) {
        const resultNumber = match[1]
        const content = match[2].trim()
        results[`result_${resultNumber}`] = content
      }
    })
    return results
  }

  const handleDrawerOpen = () => {
    setState({ right: true })
  }

  const toggleDrawer = (anchor, open) => (event) => {
    event.stopPropagation()
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  useEffect(() => {
    if (components.length > 0) {
      setLastOutput(components[components.length - 1].output)
    }
  }, [components])

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          handleDrawerOpen()
          fetchLogDetail(logId)
        }}
      >
        <FindInPageOutlinedIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
        onClick={(e) => e.stopPropagation()}
      >
        <div style={{ width: "700px", backgroundColor: "#fcfcfc", height: "100vh", position: "relative" }}>
          <>
            <header
              style={{
                display: "flex",
                gap: "1.5rem",
                position: "relative",
                alignItems: "center",
                padding: "1.25rem 1.5rem",
                boxShadow: "rgba(61, 61, 61, 0.6) 0px 2px 4px -1px",
                zIndex: "1",
              }}
            >
              <IconButton onClick={toggleDrawer("right", false)}>
                <CloseIcon />
              </IconButton>
              <p style={{ fontSize: "1.3rem", fontWeight: 700 }}>
                {new Date(parseInt(createTime)).toLocaleString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })}
              </p>
            </header>
            <div className={LogFragmentsStyles.logContainer}>
              {isLocalLoading ? (
                <>
                  <Skeleton height={16} width="50%" />
                  <div className={`${LogFragmentsStyles.logItem} ${LogFragmentsStyles.output}`}>
                    <Skeleton height={24} width={200} style={{ marginBottom: 24 }} />
                    <Skeleton height={16} width={80} />
                    <Skeleton height={44} />
                  </div>
                </>
              ) : (
                <>
                  <div className={LogFragmentsStyles.logHead}>
                    <h5 style={{ display: "flex", gap: "0.3rem" }}>
                      Total Credits Used
                      <BasicTooltip tooltip="The total exhausted credit of LLM executions within this project, excluding RAG and functions which do not impact the credit count." />
                      :
                    </h5>
                    <span>{creditCount}</span>
                  </div>
                  <div className={LogFragmentsStyles.logItem}>
                    <CardHeader nodeType="query" />
                    <DataSection type="query" label="Data In" content={input} />
                  </div>
                  {components.map((entry, index) => (
                    <div key={index} className={LogFragmentsStyles.logItem}>
                      <CardHeader nodeType={entry.type} />
                      {["lm", "df", "af"].includes(entry.type) ? (
                        <div>
                          {entry.type === "lm" && (
                            <>
                              <h5>
                                {`Model: `}
                                <span>{mapModelName(entry.model_name)}</span>
                              </h5>
                              <h5>
                                {`Credits Used: `}
                                <span>{entry.credit_count}</span>
                              </h5>
                              <h5>
                                {`Execution Time: `}
                                <span>{entry.time_spent}s</span>
                              </h5>
                            </>
                          )}
                          {entry.type === "df" && (
                            <>
                              <h5>
                                {`RAG Score: `}
                                <span>{(entry.score * 100).toFixed(1)}</span>
                              </h5>
                              <h5>
                                {`Execution Time: `}
                                <span>{entry.time_spent}s</span>
                              </h5>
                              {entry.file?.length ? (
                                <h5>
                                  {`Citation: `}
                                  <span>{entry.file.replace(/,(\S)/g, ", $1")}</span>
                                </h5>
                              ) : null}
                            </>
                          )}
                          {entry.type === "af" && (
                            <>
                              <h5>
                                {`Execution Time: `}
                                <span>{entry.time_spent}s</span>
                              </h5>
                              <h5>
                                {`Citation: `}
                                <span>{FUNCTION_DETAILS[entry.afunction_name]?.name || entry.afunction_name}</span>
                              </h5>
                            </>
                          )}
                          <DataSection label="Data In" content={entry.input} />
                          <DataSection
                            label="Data Out"
                            content={entry.type === "df" ? parseResults(entry.output) : entry.output}
                          />
                        </div>
                      ) : entry.type === "sf" ? (
                        <div>
                          <h5>
                            {`Model: `}
                            <span>{mapModelName(entry.model_name)}</span>
                          </h5>
                          <h5>
                            {`Credits Used: `}
                            <span>{entry.credit_count}</span>
                          </h5>
                          <h5>
                            {`Execution Time: `}
                            <span>{entry.time_spent}s</span>
                          </h5>
                          {entry.files_referred && (
                            <h5>
                              {`Citation: `}
                              <span>{[...new Set(entry.files_referred)].join(", ")}</span>
                            </h5>
                          )}
                          <DataSection label="Data In" content={entry.input} />
                          {entry.steps
                            ? entry.steps.map((step, index) => (
                                <DataSection
                                  key={index}
                                  type={step.tool === "tavily search" ? "tavily" : "other"}
                                  label={FUNCTION_DETAILS[step.tool]?.name || "Error"}
                                  content={step.log}
                                />
                              ))
                            : null}
                          <DataSection
                            label="Data Out"
                            content={entry.type === "df" ? parseResults(entry.output) : entry.output}
                          />
                        </div>
                      ) : entry.type === "code" ? (
                        <div>
                          <h5>
                            {`Credits Used: `}
                            <span>{entry.credit_count}</span>
                          </h5>
                          <h5>
                            {`Execution Time: `}
                            <span>{entry.time_spent}s</span>
                          </h5>
                          <DataSection label="Data In" type="json" content={JSON.stringify(entry.input)} />
                          <DataSection
                            label="Custom Script"
                            type={entry.language ? entry.language : "python"}
                            content={entry.code}
                          />
                          <DataSection label="Data Out" type="json" content={JSON.stringify(entry.output)} />
                        </div>
                      ) : null}
                    </div>
                  ))}
                  <div className={`${LogFragmentsStyles.logItem} ${LogFragmentsStyles.output}`}>
                    <CardHeader nodeType="output" />
                    <DataSection type="output" label="Data Out" content={output} />
                  </div>
                </>
              )}
            </div>
          </>
        </div>
      </Drawer>
    </div>
  )
}
