// Essential Imports
import React, { useState, useEffect, useContext } from "react"
import { Stack, Skeleton, Drawer } from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"

import { SuccessContext, ErrorContext } from "../helper/AlertContext"
import { LoadingContext } from "../helper/LoadingContext"
import {
  availableData,
  availableApps,
  availableLlm,
  appCheckEndpoint,
  integrationsConfig,
} from "../components/template/Integrations"
import AppDirectoryItem from "../components/items/AppDirectoryItem"
import DiscordConfigDrawer from "../components/sections/Drawer/DiscordConfigDrawer"
import SlackConfigDrawer from "../components/sections/Drawer/SlackConfigDrawer"
import request from "../api/axios"

import ActionDrawerStyles from "../components/sections/Drawer/ActionDrawer.module.css"

const drawerComponents = {
  discord: DiscordConfigDrawer,
  slack: SlackConfigDrawer,
}

export default function AppDirectory() {
  const [localLoading, setLocalLoading] = useState(false)
  const loadingContext = useContext(LoadingContext)
  const successContext = useContext(SuccessContext)
  const errorContext = useContext(ErrorContext)
  const [authResults, setAuthResults] = useState([])
  const [windowObjectReference, setWindowObjectReference] = useState(null)
  const [previousUrl, setPreviousUrl] = useState(null)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)
  const [activeDropdown, setActiveDropdown] = useState(null)
  const isCustom = localStorage.getItem("vext_enterprise")

  const toggleDrawer = (title = null) => {
    setOpenDrawer(!openDrawer)
    setCurrentItem(title)
    if (openDrawer) setActiveDropdown(null)
  }

  const needsAuthentication = (service) => {
    const serviceStatus = authResults.find((res) => res.service === service)
    if (serviceStatus && !serviceStatus.status) {
      return false
    }
    return true
  }

  const fetchIntegration = async () => {
    loadingContext.setIsLoading(true)
    const promises = appCheckEndpoint.map((appCheckEndpoint) => request.get(`${appCheckEndpoint.url}`))

    try {
      const results = await Promise.all(promises)
      const authData = results.map((result, index) => ({
        service: appCheckEndpoint[index].service,
        status: result.data.text,
      }))
      setAuthResults(authData)
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      loadingContext.setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchIntegration()
  }, [])

  // Authenticate integrations
  const authenticateService = async (service) => {
    if (!integrationsConfig[service]) {
      console.error("Unknown service:", service)
      return
    }

    const { postUrl, authUrl } = integrationsConfig[service]

    const receiveMessage = async (event) => {
      const { data, origin } = event

      if (!data || data.source !== "Vext Authentication" || origin !== window.location.origin) {
        return
      }

      const params = new URLSearchParams(String(data.payload).slice(1))
      const code = params.get("code")
      window.removeEventListener("message", receiveMessage)

      try {
        const response = await request.post(postUrl, { code: code })
        if (response.data.text === "ok") {
          fetchIntegration()
          successContext.setSuccess(true)
          successContext.setSuccessMsg("App successfully authenticated")

          if (availableApps.some((app) => app.id === service)) {
            toggleDrawer({ id: service, title: service })
          }
        } else {
          throw new Error("Authentication failed")
        }
      } catch (error) {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
      } finally {
        loadingContext.setIsLoading(false)
      }
    }

    window.removeEventListener("message", receiveMessage)
    const newWindowReference = window.open(
      authUrl,
      `${service} Authentication`,
      "toolbar=no, menubar=no, width=980, height=720, top=100, left=100",
    )
    setWindowObjectReference(newWindowReference)
    window.addEventListener("message", receiveMessage, false)
    setPreviousUrl(authUrl)
  }

  const renderDrawerContent = () => {
    if (!currentItem || !drawerComponents[currentItem.id]) {
      return null
    }
    const SpecificDrawerComponent = drawerComponents[currentItem.id]
    return <SpecificDrawerComponent />
  }

  return loadingContext.isLoading ? (
    <Stack spacing={2}>
      <Skeleton variant="rounded" animation="wave" height={25} sx={{ maxWidth: "10rem" }} />
      <Skeleton variant="rounded" animation="wave" height={100} sx={{ maxWidth: "43rem" }} />
      <Skeleton variant="rounded" animation="wave" height={100} sx={{ maxWidth: "43rem" }} />
      <Skeleton variant="rounded" animation="wave" height={100} sx={{ maxWidth: "43rem" }} />
    </Stack>
  ) : (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "4rem" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <div style={{ marginBottom: "0.5rem" }}>
            <h3 style={{ marginBottom: "0.5rem" }}>Data Sources</h3>
            <p>Applications that offer a wide variety of data sources for importing into your custom AI.</p>
          </div>
          {availableData.map((item, index) => (
            <AppDirectoryItem
              key={index}
              imageSrc={item.imgSrc}
              id={item.id}
              title={item.name}
              desc={item.desc}
              external={item.external}
              needsAuth={needsAuthentication(item.id)}
              available={item.available}
              buttonText={item.buttonText}
              localLoading={localLoading}
              setLocalLoading={setLocalLoading}
              activeDropdown={activeDropdown}
              setActiveDropdown={setActiveDropdown}
              authenticateService={authenticateService}
            />
          ))}
          <a
            className="link"
            onClick={(event) => {
              event.preventDefault()
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSd82xLjWXErUi7-d6GpXf1mNTs9lA0tZ_OVFnO6GLo3Wn6B4A/viewform",
                "_blank",
              )
            }}
            style={{ cursor: "pointer", textDecoration: "underline", width: "fit-content" }}
          >
            Suggest an Integration
          </a>
        </div>
        {!isCustom && (
          <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <div style={{ marginBottom: "0.5rem" }}>
              <h3 style={{ marginBottom: "0.5rem" }}>Apps</h3>
              <p>Apps that can be powered by your custom AI with no coding required.</p>
            </div>
            {availableApps.map((item, index) => (
              <AppDirectoryItem
                key={index}
                imageSrc={item.imgSrc}
                id={item.id}
                title={item.name}
                desc={item.desc}
                link={item.appLink}
                external={item.external}
                needsAuth={needsAuthentication(item.id)}
                available={item.available}
                buttonText={item.buttonText}
                drawer={item.drawer}
                localLoading={localLoading}
                setLocalLoading={setLocalLoading}
                activeDropdown={activeDropdown}
                setActiveDropdown={setActiveDropdown}
                toggleDrawer={toggleDrawer}
                authenticateService={authenticateService}
              />
            ))}
            <a
              className="link"
              onClick={(event) => {
                event.preventDefault()
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSd82xLjWXErUi7-d6GpXf1mNTs9lA0tZ_OVFnO6GLo3Wn6B4A/viewform",
                  "_blank",
                )
              }}
              style={{ cursor: "pointer", textDecoration: "underline", width: "fit-content" }}
            >
              Suggest an Integration
            </a>
          </div>
        )}
        {!isCustom && (
          <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <div style={{ marginBottom: "0.5rem" }}>
              <h3 style={{ marginBottom: "0.5rem" }}>LLMs</h3>
              <p>Bring your own LLM to the platform.</p>
            </div>
            {availableLlm.map((item, index) => (
              <AppDirectoryItem
                key={index}
                imageSrc={item.imgSrc}
                id={item.id}
                title={item.name}
                desc={item.desc}
                link={item.appLink}
                external={item.external}
                needsAuth={needsAuthentication(item.id)}
                available={item.available}
                buttonText={item.buttonText}
                authenticateService={authenticateService}
              />
            ))}
            <a
              className="link"
              onClick={(event) => {
                event.preventDefault()
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSd82xLjWXErUi7-d6GpXf1mNTs9lA0tZ_OVFnO6GLo3Wn6B4A/viewform",
                  "_blank",
                )
              }}
              style={{ cursor: "pointer", textDecoration: "underline", width: "fit-content" }}
            >
              Suggest an Integration
            </a>
          </div>
        )}
      </div>
      <>
        <Drawer anchor="right" open={openDrawer} onClose={() => toggleDrawer()}>
          <section className={ActionDrawerStyles.drawer}>
            <header className={ActionDrawerStyles.title}>
              <div style={{ display: "flex", gap: "1rem" }}>
                <CloseIcon onClick={() => toggleDrawer()} className="hoverIcon" style={{ cursor: "pointer" }} />
                <p style={{ fontSize: "1.3rem", fontWeight: 700 }}>
                  {currentItem ? currentItem.title.charAt(0).toUpperCase() + currentItem.title.slice(1) : ""}
                </p>
              </div>
            </header>
            {renderDrawerContent()}
          </section>
        </Drawer>
      </>
    </>
  )
}