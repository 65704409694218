import React, { useContext, useEffect, useState } from "react"
import { Button, IconButton } from "@mui/material"
import { Check as CheckIcon, MoreHorizOutlined as MoreHorizIcon, TimerOutlined as TimerIcon } from "@mui/icons-material"
import { ClickAwayListener } from "@mui/base"

import SelectField from "./SelectField"
import { ErrorContext, SuccessContext } from "../../helper/AlertContext"
import { organizationService } from "../../api/services"

import AccountTabsStyles from "../../styles/AccountTabs.module.css"

const MemberRow = ({ userId, name, email, status, role, roleOptions = [], handleReinvite, handleRemoveUser }) => {
  const [roleInput, setRoleInput] = useState("")
  const [showDropdown, setShowDropdown] = useState(false)
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { setSuccess, setSuccessMsg } = useContext(SuccessContext)

  const statusMapping = {
    a: {
      text: "Accepted",
      icon: <CheckIcon style={{ color: "#24dd88", fontSize: "0.8rem" }} />,
    },
    p: {
      text: "Pending",
      icon: <TimerIcon color="warning" style={{ fontSize: "0.8rem" }} />,
    },
  }
  const handleOnChange = async (event) => {
    const roleId = event.target.value

    if (roleId) {
      try {
        await organizationService.updateRole({ user_id: userId, role_id: roleId })
        setRoleInput(roleId)
        setSuccess(true)
        setSuccessMsg("User role has changed.")
      } catch (error) {
        setError(true)
        if (error.response?.data?.message) {
          setErrorMsg(error.response.data.message)
        } else {
          setErrorMsg(error.message)
        }
      }
    }
  }
  const toggleActionDialog = () => {
    setShowDropdown(!showDropdown)
  }

  useEffect(() => {
    setRoleInput(role)
  }, [role])

  return (
    <>
      <div>{name.trim() || "-"}</div>
      <div>{email}</div>
      <div style={{ gap: 8 }}>
        {statusMapping[status].icon}
        <span>{statusMapping[status].text}</span>
      </div>
      <div style={{ position: "relative" }}>
        <SelectField
          divStyle={{ width: "100%" }}
          options={roleOptions}
          value={roleInput}
          onChange={handleOnChange}
          disabled={status === "p"}
        />
        <IconButton onClick={toggleActionDialog}>
          <MoreHorizIcon />
        </IconButton>
        {showDropdown && (
          <ClickAwayListener onClickAway={toggleActionDialog}>
            <div className={AccountTabsStyles.actionDialog}>
              {status === "p" && (
                <Button
                  onClick={() => {
                    handleReinvite(email, role)
                    toggleActionDialog()
                  }}
                  sx={{ justifyContent: "start", textTransform: "none", width: "100%" }}
                >
                  Re-invite
                </Button>
              )}
              <Button
                onClick={() => {
                  handleRemoveUser(userId)
                  toggleActionDialog()
                }}
                sx={{ justifyContent: "start", textTransform: "none", width: "100%" }}
                color="error"
              >
                Remove
              </Button>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </>
  )
}

export default MemberRow
