import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ReactFlowProvider } from "reactflow"
import { Button, Drawer, IconButton, Skeleton, Tooltip } from "@mui/material"
import {
  Close as CloseIcon,
  MoreHorizOutlined as MoreHorizIcon,
  ScienceOutlined as ScienceIcon,
  Code as CodeIcon,
} from "@mui/icons-material"
import { ClickAwayListener } from "@mui/base"

import ActionFlow from "../components/template/ActionFlow"
import BasicSwitch from "../components/items/Toggle"
import TextArea from "../components/items/TextArea"
import LocalLoadingBar from "../components/items/LocalLoadingBar"
import ConfirmationDialog from "../components/items/ConfirmationDialog"
import BasicTooltip from "../components/items/BasicTooltip"
import TestWindow from "../components/children/TestWindow"
import ApiAccessDrawer from "../components/sections/Drawer/ApiAccessDrawer"
import { accountService, appService } from "../api/services"
import { LoadingContext } from "../helper/LoadingContext"
import { SuccessContext, ErrorContext } from "../helper/AlertContext"
import { TitleContext } from "../helper/TitleContext"

import ProjectDetailStyles from "../styles/ProjectDetail.module.css"
import "../styles/Items.css"

const DEFAULT_EDIT_STATE = { name: false, description: false }

const ProjectDetail = () => {
  const navigate = useNavigate()
  const { app_id: appId } = useParams()
  const [customVariables, setCustomVariables] = useState(null)
  const [projectName, setProjectName] = useState("")
  const [projectDescription, setProjectDescription] = useState("")
  const [projectStatus, setProjectStatus] = useState(false)
  const [versions, setVersions] = useState([])
  const [flowData, setFlowData] = useState({ nodes: [], edges: [] })
  const [memory, setMemory] = useState(null)
  const [enableLog, setEnableLog] = useState(true)
  const [enableCitation, setEnableCitation] = useState(false)
  const [drawerState, setDrawerState] = useState({ show: false, type: "" })
  const [openDialog, setOpenDialog] = useState(false)
  const [openPgDialog, setOpenPgDialog] = useState(false)
  const [playgroundData, setPlaygroundData] = useState(null)
  const [isEditingField, setIsEditingField] = useState(DEFAULT_EDIT_STATE)
  const [isLocalLoading, setIsLocalLoading] = useState(false)
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [hasConfigNode, setHasConfigNode] = useState(true)
  const [apiView, setApiView] = useState(true)
  const { isLoading, setIsLoading } = useContext(LoadingContext)
  const { setSuccess, setSuccessMsg } = useContext(SuccessContext)
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { setTitle } = useContext(TitleContext)

  const handleCreateProject = async () => {
    const payload = {
      is_close: false,
      name: "Untitled",
      description: "This is an incredibly smart AI.",
    }

    try {
      const { data } = await appService.createProject(payload)

      accountService.updateTaskList({ create_app: true })
      setSuccess(true)
      setSuccessMsg("AI project created")
      navigate(`/ai-projects/${data.id}`)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    }
  }
  const toggleDeleteDialog = () => {
    setIsOpenDialog(!isOpenDialog)
  }
  const handleDeletion = async () => {
    try {
      setIsLocalLoading(true)
      await appService.removeProject(appId)
      navigate("/ai-projects")
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  const handleReadProject = async () => {
    setIsLocalLoading(true)
    try {
      const { data } = await appService.getProject(appId)
      const versionId = data.versions[0].id

      await handleReadVersion(versionId)
      updateHeader(data.name)
      setProjectName(data.name)
      setProjectDescription(data.description)
      setProjectStatus(!data.is_close)
      setEnableLog(data.is_logging)
      setEnableCitation(data.is_citation)
      setPlaygroundData({ app: { http_url: data.http_url, id: data.id, api_key_available: data.api_key_available } })
      setVersions(data.versions)
      setIsLocalLoading(false)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
      if (error.response.status === 404) {
        navigate(`/ai-projects/`)
      }
    }
  }
  const handleReadVersion = async (id) => {
    try {
      const { data } = await appService.getFlow(id)
      setFlowData({ nodes: data.components, edges: data.edges })
      setMemory(data.is_need_memory)
      setCustomVariables(data.custom_variable_bank)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    }
  }
  const handleUpdateFlow = async (flowNodes, flowEdges) => {
    const versionId = versions[0].id
    const components = flowNodes
      .filter((node) => node.id !== "nstart")
      .map((node) => ({
        type: node.data.name,
        name: node.data.label,
        position_x: node.position.x,
        position_y: node.position.y,
        ...(node.id.indexOf("temp-node-") === 0 ? { nid: node.id.replace("temp-node-", "n") } : { id: node.id }),
      }))
    const edges = flowEdges
      .filter((edge) => edge.source !== "nstart")
      .map((edge) => ({
        source: edge.source.indexOf("temp-node-") === 0 ? edge.source.replace("temp-node-", "n") : edge.source,
        target: edge.target.indexOf("temp-node-") === 0 ? edge.target.replace("temp-node-", "n") : edge.target,
      }))

    const findConfig = components.find((item) => item.type === "lm" || item.type === "sf")
    const payload = {
      components,
      edges,
    }

    if (!findConfig) {
      payload.is_need_memory = false
    }
    try {
      const { data } = await appService.updateFlow(versionId, payload)

      await handleReadProject()
      setFlowData({ nodes: data.components, edges: data.edges })
      setMemory(data.is_need_memory)
      return data
    } catch (error) {
      throw new Error(error.message)
    }
  }
  const handleUpdateComponent = async (componentId, payload) => {
    try {
      const response = await appService.updateComponent(componentId, payload)
      await appService.createVersion(response.data.app_ver_id) // [2024-05-20] hide publish button and directly call publish API during the transition period
      return response
    } catch (error) {
      throw new Error(error.message)
    }
  }
  const handleUpdateFlowConfig = async (payload) => {
    const versionId = versions[0].id

    setIsLocalLoading(true)
    try {
      const { data } = await appService.updateFlow(versionId, payload)

      await appService.createVersion(data.id) // [2024-05-20] hide publish button and directly call publish API during the transition period
      await handleReadProject()
      setMemory(data.is_need_memory)
      setCustomVariables(data.custom_variable_bank)
      setSuccess(true)
      setSuccessMsg("AI project updated")
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  // const getLastestVersion = () => {
  //   return `${versions[0].version} published`
  // }
  const gotoLogsPage = () => {
    sessionStorage.setItem("selectedAppId", appId)
    navigate("/logs")
  }
  const updateHeader = (projectName) => {
    setTitle(projectName)
    sessionStorage.setItem("appName", projectName)
  }
  const toggleEditFieid = (event) => {
    const field = event?.target.getAttribute("value")
    const fieldMapping = {
      name: projectName,
      description: projectDescription,
    }

    if (field) {
      setIsEditingField({ ...DEFAULT_EDIT_STATE, [field]: fieldMapping[field] })
    } else {
      setIsEditingField(DEFAULT_EDIT_STATE)
    }
  }
  const toggleDrawer = (type = "") => {
    if (type && typeof type === "string") {
      setDrawerState({ show: true, type })
    } else {
      setDrawerState({ show: false, type })
    }
  }
  const toggleProjectStatus = (value) => {
    setProjectStatus(value)
    handleUpdateProject({ is_close: !value })
  }
  const togglePgView = () => {
    setApiView(!apiView)
  }
  const toggleDialog = () => {
    setOpenDialog(!openDialog)
  }
  const togglePgDialog = () => {
    setOpenPgDialog(!openPgDialog)
  }
  const handleUpdateProject = async (payload) => {
    setIsLocalLoading(true)
    try {
      const { data } = await appService.updateProject(appId, payload)

      updateHeader(data.name)
      setEnableLog(data.is_logging)
      setEnableCitation(data.is_citation)
      setSuccess(true)
      setSuccessMsg("AI project updated")
    } catch (error) {
      setProjectStatus(projectStatus)
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  // const handlePublish = async () => {
  //   try {
  //     await appService.createVersion(versions[0].id)
  //     setSuccess(true)
  //     setSuccessMsg("Published.")
  //   } catch (error) {
  //     setError(true)
  //     setErrorMsg(error.response?.data?.text)
  //   }
  // }

  useEffect(() => {
    const asyncUseEffect = async () => {
      setIsLoading(true)
      if (!appId) {
        await handleCreateProject()
        setFlowData({ nodes: [], edges: [] })
      } else {
        await handleReadProject()
      }
      setIsLoading(false)
    }

    asyncUseEffect()
  }, [appId])
  useEffect(() => {
    if (flowData.nodes) {
      setHasConfigNode(flowData.nodes.find((item) => item.type === "lm" || item.type === "sf"))
    }
  }, [flowData])

  return (
    <>
      <section className={ProjectDetailStyles.editableArea}>
        {isLoading || !flowData.nodes ? (
          <div className={ProjectDetailStyles.header}>
            <div style={{ display: "block" }}>
              <Skeleton variant="rounded" animation="wave" height={24} width="40%" />
              <Skeleton variant="rounded" animation="wave" height={16} sx={{ marginTop: 0.5 }} />
            </div>
          </div>
        ) : (
          <div className={ProjectDetailStyles.header}>
            <div>
              <BasicSwitch defaultChecked={projectStatus} handleSave={toggleProjectStatus} disabled={isLocalLoading} />
              <div className={ProjectDetailStyles.fieldContainer}>
                {isEditingField.name ? (
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    onClickAway={() => {
                      if (projectName) {
                        handleUpdateProject({ name: projectName })
                      } else {
                        setProjectName(isEditingField.name)
                      }
                      toggleEditFieid()
                    }}
                  >
                    <div>
                      <TextArea
                        value={projectName}
                        mLength={100}
                        onChange={setProjectName}
                        onPressEnter={() => {
                          if (projectName) {
                            handleUpdateProject({ name: projectName })
                            toggleEditFieid()
                          }
                        }}
                      />
                    </div>
                  </ClickAwayListener>
                ) : (
                  <h3 className="appConfig" onClick={toggleEditFieid} value="name">
                    {projectName}
                  </h3>
                )}
                {isEditingField.description ? (
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    onClickAway={() => {
                      if (projectDescription) {
                        handleUpdateProject({ description: projectDescription })
                      } else {
                        setProjectDescription(isEditingField.description)
                      }
                      toggleEditFieid()
                    }}
                  >
                    <div>
                      <TextArea
                        value={projectDescription}
                        mLength={150}
                        onChange={setProjectDescription}
                        onPressEnter={() => {
                          if (projectDescription) {
                            handleUpdateProject({ description: projectDescription })
                            toggleEditFieid()
                          }
                        }}
                      />
                    </div>
                  </ClickAwayListener>
                ) : (
                  <p onClick={toggleEditFieid} style={{ marginTop: 4 }} className="appConfig" value="description">
                    {projectDescription}
                  </p>
                )}
              </div>
            </div>
            <div className={ProjectDetailStyles.actionArea}>
              {/* <div className={ProjectDetailStyles.versionText}>{getLastestVersion()}</div> */}
              <Tooltip title={!flowData.nodes?.length && "Please add at least one action to the workflow."}>
                <span>
                  <Button
                    onClick={() => toggleDrawer("playground")}
                    disabled={!flowData.nodes?.length}
                    variant="outlined"
                    startIcon={<ScienceIcon />}
                  >
                    Playground
                  </Button>
                </span>
              </Tooltip>
              <Button onClick={() => toggleDrawer("api")} variant="outlined" startIcon={<CodeIcon />}>
                API Access
              </Button>
              {/* <Button onClick={handlePublish} variant="contained">
                Publish
              </Button> */}
              <IconButton onClick={toggleDialog}>
                <MoreHorizIcon />
              </IconButton>
              {openDialog && (
                <ClickAwayListener onClickAway={toggleDialog}>
                  <div className={ProjectDetailStyles.moreOptions}>
                    <Tooltip
                      placement="top"
                      title={hasConfigNode ? null : "Add an Agent or LLM to the project to enable memory."}
                    >
                      <span>
                        <Button
                          onClick={() => handleUpdateFlowConfig({ is_need_memory: !memory })}
                          disabled={isLocalLoading || !hasConfigNode}
                        >
                          Memory: <span>{memory ? "On" : "Off"}</span>
                        </Button>
                      </span>
                    </Tooltip>
                    <Button
                      onClick={async () => {
                        setIsLocalLoading(true)
                        await handleUpdateProject({ is_citation: !enableCitation })
                        setIsLocalLoading(false)
                      }}
                      disabled={isLocalLoading}
                    >
                      Citation: <span>{enableCitation ? "On" : "Off"}</span>
                    </Button>
                    <Button
                      onClick={async () => {
                        setIsLocalLoading(true)
                        await handleUpdateProject({ is_logging: !enableLog })
                        setIsLocalLoading(false)
                      }}
                      disabled={isLocalLoading}
                    >
                      Logs: <span>{enableLog ? "On" : "Off"}</span>
                    </Button>
                    <Button onClick={gotoLogsPage}>View Logs</Button>
                    <Button onClick={toggleDeleteDialog} color="error">
                      Delete
                    </Button>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
        )}
        <div style={{ height: "60vh", position: "relative" }}>
          <LocalLoadingBar localLoading={isLocalLoading} />
          {isLocalLoading && <div className={ProjectDetailStyles.overlay} />}
          <ReactFlowProvider>
            <ActionFlow
              key={appId}
              customVariables={customVariables}
              flowInput={flowData}
              appliData={playgroundData}
              handleGetProject={handleReadProject}
              handleUpdateFlow={handleUpdateFlow}
              handleUpdateFlowConfig={handleUpdateFlowConfig}
              handleUpdateComponent={handleUpdateComponent}
            />
          </ReactFlowProvider>
        </div>
      </section>
      <Drawer anchor="right" open={drawerState.show} onClose={toggleDrawer}>
        <section className={ProjectDetailStyles.drawer}>
          <header className={ProjectDetailStyles.title}>
            <div style={{ display: "flex", gap: "1rem" }}>
              <CloseIcon onClick={toggleDrawer} className="hoverIcon" style={{ cursor: "pointer" }} />
              <p style={{ fontSize: "1.3rem", fontWeight: 700 }}>
                {drawerState.type === "playground" && "Playground"}
                {drawerState.type === "api" && "API Access"}
              </p>
            </div>
            {drawerState.type === "playground" && (
              <IconButton onClick={togglePgDialog}>
                <MoreHorizIcon />
              </IconButton>
            )}
            {openPgDialog && (
              <ClickAwayListener onClickAway={togglePgDialog}>
                <div className={ProjectDetailStyles.morePgOptions}>
                  <div>
                    API View <BasicTooltip tooltip={"Display detailed JSON request and response data."} />
                  </div>
                  <BasicSwitch defaultChecked={apiView} handleSave={togglePgView} disabled={isLocalLoading} />
                </div>
              </ClickAwayListener>
            )}
          </header>
          <div className={ProjectDetailStyles.main}>
            {drawerState.type === "playground" && (
              <TestWindow
                appliData={playgroundData}
                viewLog={gotoLogsPage}
                customVariables={customVariables}
                apiView={apiView}
              />
            )}
            {drawerState.type === "api" && (
              <ApiAccessDrawer appliData={playgroundData} customVariables={customVariables} />
            )}
          </div>
        </section>
      </Drawer>
      <ConfirmationDialog
        open={isOpenDialog}
        handlePrimary={handleDeletion}
        handleSecondary={toggleDeleteDialog}
        title="Confirm Delete"
        content="Are you sure you want to delete this project? This action cannot be undone."
        primaryButtonText={isLocalLoading ? "Deleting..." : "Delete"}
        primaryButtonColor="error"
        primaryButtonDisabled={isLocalLoading}
      />
    </>
  )
}

export default ProjectDetail
