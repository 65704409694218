// Library Imports
import React, { useState, useEffect, useContext } from "react"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Skeleton,
  Tooltip,
  IconButton,
  Collapse,
} from "@mui/material"
import {
  WarningAmber as WarningAmberIcon,
  Download as DownloadIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material"
import { ClickAwayListener } from "@mui/base"

// Component Imports
import { LoadingContext } from "../helper/LoadingContext"
import { ErrorContext } from "../helper/AlertContext"
import DateRange from "../components/items/DateRange"
import LogFragments from "../components/children/LogFragments"
import SelectField from "../components/items/SelectField"
import BasicTooltip from "../components/items/BasicTooltip"
import CustomTablePagination from "../components/items/TablePagination"
import EmptyState from "../components/items/EmptyState"
import JsonSnippet from "../components/items/JsonSnippet"

import { accountService, appService, logService } from "../api/services"
import { formatJsonString } from "../utils"

import LogsStyles from "../styles/Logs.module.css"
import "../styles/Code.css"

dayjs.extend(utc)

function LogEntry({ entry }) {
  const [expandLog, setExpandLog] = useState(false)

  const truncateText = (text, maxLength) => {
    if (text == null) {
      return ""
    }
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text
  }

  const setScore = (score) => {
    if (!score || score <= -99) {
      return "N/A"
    }
    if (score * 100 < 80) {
      return (
        <Tooltip
          title={
            "A score under 80 suggests low relevance. Adding more relevant data sources or updating the existing ones may improve accuracy."
          }
        >
          <span>
            {(score * 100).toFixed(1)}
            <WarningAmberIcon
              color="warning"
              sx={{
                fontSize: "0.8rem",
                paddingX: 0.5,
              }}
            />
          </span>
        </Tooltip>
      )
    }
    return (score * 100).toFixed(1)
  }

  return (
    <>
      <TableRow onClick={() => setExpandLog(!expandLog)} style={{ cursor: "pointer" }}>
        <TableCell>
          {new Date(parseInt(entry.created_time)).toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          })}
        </TableCell>
        <TableCell style={{ fontSize: "0.7rem", fontStyle: "italic" }}>{entry.identifier_id}</TableCell>
        <TableCell style={{ maxWidth: "200px" }}>{truncateText(entry.input, 100)}</TableCell>
        <TableCell style={{ maxWidth: "200px" }}>{truncateText(entry.output, 100)}</TableCell>
        <TableCell
          style={{
            textAlign: "center",
          }}
        >
          {setScore(entry.rag_score)}
        </TableCell>
        <TableCell
          style={{
            textAlign: "center",
          }}
        >
          {entry.total_credit}
        </TableCell>
        <TableCell>
          <LogFragments createTime={entry.created_time} logId={entry.id} creditCount={entry.total_credit} input={formatJsonString(entry.input)} output={formatJsonString(entry.output)} />
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setExpandLog(!expandLog)}>
            {expandLog ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0, backgroundColor: "#3d3d3d10" }} colSpan={8}>
          <Collapse in={expandLog} timeout="auto" unmountOnExit>
            <div style={{ paddingBottom: "1.5rem", paddingTop: "0.5rem" }}>
              <div>
                <h5>Input</h5>
                <JsonSnippet>{formatJsonString(entry.input)}</JsonSnippet>
              </div>
              <div>
                <h5>Output</h5>
                <JsonSnippet>{formatJsonString(entry.output)}</JsonSnippet>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default function Logs() {
  const today = dayjs()
  const sevenDaysAgo = today.subtract(7, "day")
  const loadingContext = useContext(LoadingContext)
  const errorContext = useContext(ErrorContext)
  const [appList, setAppList] = useState([])
  const [selectedApp, setSelectedApp] = useState("")
  const [showDropdown, setShowDropdown] = useState(false)
  const [logData, setLogData] = useState([])
  const [logDataCount, setLogDataCount] = useState(0)
  const [page, setPage] = useState(0)
  const [nextPageUrl, setNextPageUrl] = useState(null)
  const [previousPageUrl, setPreviousPageUrl] = useState(null)
  const [prevSelectedProject, setPrevSelectedProject] = useState(null)
  const [startDate, setStartDate] = useState(sevenDaysAgo)
  const [endDate, setEndDate] = useState(today)

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  const handleAppChange = (e) => {
    const newSelectedApp = e.target.value

    if (newSelectedApp === "Select..." || newSelectedApp === "") {
      setSelectedApp(prevSelectedProject)
    } else {
      setSelectedApp(newSelectedApp)
      setPrevSelectedProject(newSelectedApp)
      loadingContext.setIsLoading(true)
    }
  }

  const handleExportFile = async (type = "csv") => {
    const payload = {
      appId: selectedApp,
      startDate: startDate.utc().format("YYYYMMDD"),
      endDate: endDate.utc().format("YYYYMMDD"),
    }

    try {
      const { data } = await logService.downloadFile(payload, { dl_type: type })
      const file = new Blob([data], {
        type: type === "xlsx" ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "text/csv",
      })
      const appName = appList.find((app) => app.id === payload.appId)?.name
      const fileName = `${appName ? `${appName}_` : ""}${payload.startDate}_${payload.endDate}.${type}`
      const fileHref = document.createElement("a")

      fileHref.href = URL.createObjectURL(file)
      fileHref.setAttribute("download", fileName)
      document.body.appendChild(fileHref)
      fileHref.click()
      fileHref.parentNode.removeChild(fileHref)
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    }
  }

  const fetchLog = async ({ appId, startDate, endDate }) => {
    try {
      const { data } = await logService.getLogList({ appId, startDate, endDate })

      setNextPageUrl(data.next)
      setPreviousPageUrl(data.previous)
      setLogDataCount(data.count)
      return data.results
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
      return []
    }
  }

  // useEffect to fetch logs when make app selection
  useEffect(() => {
    const fetchSelectedAppLogs = async () => {
      if (loadingContext.setIsLoading !== true) {
        loadingContext.setIsLoading(true)
      }

      setPage(0)
      try {
        const logs = await fetchLog({
          appId: selectedApp,
          startDate: startDate.utc().format("YYYYMMDD"),
          endDate: endDate.utc().format("YYYYMMDD"),
        })

        setLogData(logs)
        if (logs && logs.length) {
          accountService.updateTaskList({ view_log: true })
        }
      } catch (error) {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
      } finally {
        loadingContext.setIsLoading(false)
      }
    }

    if (selectedApp) {
      fetchSelectedAppLogs()
    }
  }, [selectedApp, startDate, endDate])

  // Fetch all the app information
  useEffect(() => {
    const fetchData = async () => {
      loadingContext.setIsLoading(true)
      try {
        // Recursive get app list
        const response = await appService.getAllProjects()
        setAppList(response)

        // Get the app id from session storage, if available
        const storedAppId = sessionStorage.getItem("selectedAppId")

        // If storedAppId is available, use it
        if (storedAppId) {
          setLogData(logData)
          setSelectedApp(storedAppId)
          setPrevSelectedProject(storedAppId)
          // If not, default to the first app in the list
        } else if (response.length > 0) {
          setLogData(logData)
          setSelectedApp(response[0].id)
          setPrevSelectedProject(response[0].id)
        } else {
          loadingContext.setIsLoading(false)
        }
      } catch (error) {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
      }
    }
    fetchData()
  }, [])

  return (
    <>
      {loadingContext.isLoading ? (
        <>
          <div className={LogsStyles.actionRow}>
            <Skeleton variant="rounded" animation="wave" width={"21%"} height={40} />
            <Skeleton variant="rounded" animation="wave" width={"17.5%"} height={40} />
            <Skeleton variant="rounded" animation="wave" width={"17.5%"} height={40} />
          </div>
          <Stack spacing={0.5}>
            <Skeleton variant="rounded" animation="wave" height={50} />
            <Skeleton variant="rounded" animation="wave" height={50} />
            <Skeleton variant="rounded" animation="wave" height={50} />
          </Stack>
        </>
      ) : (
        <section>
          {appList.length > 0 ? (
            <>
              <div className={LogsStyles.actionRow}>
                <div className={LogsStyles.downloadContainer}>
                  <Button
                    variant="outlined"
                    sx={{ maxWidth: "fit-content", minWidth: "fit-content", pl: "0.5rem", pr: "0.5rem" }}
                    onClick={() => {
                      toggleDropdown()
                    }}
                  >
                    <DownloadIcon />
                  </Button>
                  {showDropdown && (
                    <ClickAwayListener onClickAway={toggleDropdown}>
                      <div className={LogsStyles.dialog}>
                        <Button onClick={() => handleExportFile("csv")}>.csv</Button>
                        <Button onClick={() => handleExportFile("xlsx")}>.xlsx</Button>
                      </div>
                    </ClickAwayListener>
                  )}
                </div>
                <SelectField
                  label="AI Project"
                  options={appList.map((app) => ({
                    value: app.id,
                    label: app.name,
                  }))}
                  value={selectedApp}
                  onChange={handleAppChange}
                  divStyle={{ display: "flex" }}
                  sx={{ width: "15rem" }}
                />
                <DateRange
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </div>
              <TableContainer component={Paper} className={LogsStyles.tableContainter}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "15%" }}>Timestamp</TableCell>
                      <TableCell style={{ width: "15%" }}>
                        <p>
                          Identifier
                          <BasicTooltip
                            tooltip={`This is the "channel token" that you include when making API calls to your project.`}
                          />
                        </p>
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>Input</TableCell>
                      <TableCell style={{ width: "20%" }}>Output</TableCell>
                      <TableCell style={{ width: "8%" }}>
                        <p style={{ justifyContent: "center", width: 100 }}>
                          Score
                          <BasicTooltip tooltip="This is the similarity score for the Top K vs the query. A score under 80 suggests low similarity and adding more relevant data sources is advised." />
                        </p>
                      </TableCell>
                      <TableCell style={{ minWidth: "15%" }}>
                        <p style={{ justifyContent: "center" }}>Credits Used</p>
                      </TableCell>
                      <TableCell style={{ width: "8%" }}>
                        <p style={{ justifyContent: "center", width: 82 }}>Trace</p>
                      </TableCell>
                      <TableCell style={{ width: "1%" }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logData.map((entry) => (
                      <LogEntry key={entry.id} entry={entry} />
                    ))}
                  </TableBody>
                </Table>
                <CustomTablePagination
                  page={page}
                  setPage={setPage}
                  nextPageUrl={nextPageUrl}
                  setNextPageUrl={setNextPageUrl}
                  previousPageUrl={previousPageUrl}
                  setPreviousPageUrl={setPreviousPageUrl}
                  data={logData}
                  setData={setLogData}
                  dataCount={logDataCount}
                  setDataCount={setLogDataCount}
                />
              </TableContainer>
            </>
          ) : (
            <EmptyState />
          )}
        </section>
      )}
    </>
  )
}
