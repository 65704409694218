import React, { useState } from "react"
import { Drawer, IconButton } from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"

import ConfirmationDialog from "../../items/ConfirmationDialog"
import ConfigDrawer from "./ConfigDrawer"
import DatasetDrawer from "./DatasetDrawer"
import FunctionDrawer from "./FunctionDrawer"
import SmartFunctionDrawer from "./CombinedFunctionDrawer"
import SmartRoutungDrawer from "./ClassifierDrawer"
import CodeDrawer from "./CodeDrawer"
import QueryDrawer from "./QueryDrawer"
import OutputDrawer from "./OutputDrawer"

import {
  NODE_CODE,
  NODE_CONFIG,
  NODE_DATASET,
  NODE_FUNCTION,
  NODE_SMART_FUNCTION,
  NODE_CLASSIFIER,
  NODE_QUERY,
  NODE_OUTPUT,
} from "../../template/actionflow.static"
import ActionDrawerStyles from "./ActionDrawer.module.css"

const DRAWER_MAPPING = {
  lm: {
    component: ConfigDrawer,
    title: NODE_CONFIG.data.label,
  },
  df: {
    component: DatasetDrawer,
    title: NODE_DATASET.data.label,
  },
  af: {
    component: FunctionDrawer,
    title: NODE_FUNCTION.data.label,
  },
  sf: {
    component: SmartFunctionDrawer,
    title: NODE_SMART_FUNCTION.data.label,
  },
  cls: {
    component: SmartRoutungDrawer,
    title: NODE_CLASSIFIER.data.label,
  },
  code: {
    component: CodeDrawer,
    title: NODE_CODE.data.label,
  },
  query: {
    component: QueryDrawer,
    title: NODE_QUERY.data.label,
  },
  output: {
    component: OutputDrawer,
    title: NODE_OUTPUT.data.label,
  },
}

const ActionDrawer = ({
  availableVars = [],
  drawerData,
  drawerSequence,
  customVariables,
  appliData,
  isLoading,
  onCloseDrawer,
  onSaveDrawer,
  type = "af",
  isOpen = false,
}) => {
  const drawerObject = DRAWER_MAPPING[type] || DRAWER_MAPPING["af"]
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [hasUnsaved, setHasUnsaved] = useState(false)

  const handleClose = () => {
    if (hasUnsaved) {
      setIsDialogOpen(true)
    } else {
      onCloseDrawer()
    }
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            onCloseDrawer()
          }
        }}
      >
        <section className={ActionDrawerStyles.drawer}>
          <header className={ActionDrawerStyles.title}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <p>{drawerObject.title}</p>
          </header>
          <drawerObject.component
            availableVars={availableVars}
            customVariables={customVariables}
            appliData={appliData}
            formData={drawerData}
            sequence={drawerSequence}
            isLoadingForm={isLoading}
            onSubmit={onSaveDrawer}
            setUnsaved={setHasUnsaved}
          />
        </section>
      </Drawer>
      <ConfirmationDialog
        open={isDialogOpen}
        handlePrimary={() => {
          onCloseDrawer()
          setIsDialogOpen(false)
          setHasUnsaved(false)
        }}
        handleSecondary={() => {
          if (isDialogOpen) {
            setIsDialogOpen(false)
          }
        }}
        title="Unsaved Changes"
        content="Are you sure you want to leave? There are unsaved changes. If you leave, your changes will be lost."
        primaryButtonText={"Leave"}
        primaryButtonColor="error"
        secondaryButtonText={"Stay Here"}
      />
    </>
  )
}

export default ActionDrawer
