import React, { useEffect, useState } from "react"

import BasicTooltip from "../items/BasicTooltip"
import SelectField from "../items/SelectField"
import { MODELS } from "../../utils/constants"

import ActionDrawerStyles from "../sections/Drawer/ActionDrawer.module.css"

const ModelSection = ({ allowModels = [], isLoadingForm, modelInput, modelId, maxToken, onChangeModel }) => {
  const [modelList, setModelList] = useState(MODELS)

  useEffect(() => {
    if (allowModels?.length) {
      setModelList(MODELS.filter((m) => allowModels.includes(m.value)))
    }
  }, [allowModels])
  return (
    <div className={ActionDrawerStyles.flex}>
      <h4>
        Model
        <BasicTooltip tooltip="Which LLM model to deploy to operate your LLM pipeline." />
      </h4>
      <div>
        <div style={{ flex: 1 }}>
          <SelectField options={modelList} value={modelInput} onChange={onChangeModel} disabled={isLoadingForm} />
          <div
            style={{
              fontSize: "0.8rem",
              paddingTop: "0.5rem",
              color: "#3d3d3d80",
              fontStyle: "italic",
              textAlign: "right",
            }}
          >
            {modelId && <div>Model ID: {modelId}</div>}
            {maxToken && <div>Max Output Tokens: {maxToken}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModelSection
