// Library Imports
import React, { useEffect, useContext, useState } from "react"
import { Box, Button, Skeleton, Slider, Stack } from "@mui/material"

// Component Imports
import { LoadingContext } from "../helper/LoadingContext"
import { AccountContext } from "../helper/AccountContext"
import useStripeSession from "../helper/StripePortal"
import ConfirmationDialog from "../components/items/ConfirmationDialog"
import LinearProgress from "../components/items/Progress"
import ResourceCard from "../components/items/Card"
import MessageBox from "../components/items/MessageBox"
import ChecklistCard from "../components/items/ChecklistCard"
import BasicTooltip from "../components/items/BasicTooltip"

import { accountService, appService } from "../api/services"
import { USER_CHECKLIST } from "../utils/constants"

import DashboardStyles from "../styles/Dashboard.module.css"

const CreditSlider = ({ value = 10, onChange = () => {} }) => {
  return (
    <div>
      <Box sx={{ p: "0 12px 0 4px" }}>
        <Slider defaultValue={10} step={10} value={value} min={10} max={60} onChange={onChange} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mr: -0.5 }}>
        {Array.from({ length: 5 }, (_, i) => (
          <div key={i}>{(i + 1) * 10}k</div>
        ))}
        <div>More</div>
      </Box>
      <Box sx={{ mt: 3 }}>
        {value > 50 ? (
          <div style={{ textAlign: "left" }}>
            If you need more than 50k credits/month, please contact us for a custom plan.
          </div>
        ) : (
          <div style={{ textAlign: "right" }}>
            <div>${(value * 1000).toLocaleString()} Credits / Month</div>
            <strong style={{ display: "block", marginTop: 16 }}>${value * 10} / Month</strong>
          </div>
        )}
      </Box>
    </div>
  )
}

export default function Dashboard({ profile }) {
  const [account, setAccount] = useState({})
  const [checkList, setCheckList] = useState(structuredClone(USER_CHECKLIST))
  const [showChecklistCard, setShowChecklistCard] = useState(false)
  const [dialogState, setDialogState] = useState({ show: false, type: "" })
  const [creditSliderValue, setCreditSliderValue] = useState(10)
  const loadingContext = useContext(LoadingContext)
  const { fetchAccountStatus, setSubPrompt } = useContext(AccountContext)
  const { createStripeSession } = useStripeSession()

  const handleChangeSlider = (_, value) => {
    setCreditSliderValue(value)
  }
  const handleAddQueries = async () => {
    if (creditSliderValue > 50) {
      window.open("https://calendly.com/vext-ed/30min", "_blank")
    } else {
      await createStripeSession("update")
      toggleDialog()
    }
  }
  const toggleDialog = (type = "") => {
    const { show } = dialogState
    setDialogState({ show: !show, type })
  }
  const fetchCheckList = async () => {
    try {
      const { data } = await accountService.getTaskList()

      if (data.text?.show_list) {
        let currentActiveItem = null
        const pid = await appService.getLastestProjectId()
        const updateList = checkList.map((item) => {
          item.isCompleted = data.text[item.key]
          if (!data.text[item.key] && !currentActiveItem) {
            currentActiveItem = item.key
            item.isActive = true
          }
          if (item.key === "playground" || item.key === "tune_config") {
            if (pid) {
              item.link += `/${pid}?step=${item.key}`
            } else {
              item.link = "/ai-projects/create"
            }
          }
          return item
        })

        setCheckList(updateList)
        setShowChecklistCard(true)
      } else {
        setShowChecklistCard(false)
      }
    } catch (error) {
      setShowChecklistCard(false)
    }
  }

  useEffect(() => {
    loadingContext.setIsLoading(true)
    fetchCheckList()
    fetchAccountStatus((account) => {
      setAccount(account)
      loadingContext.setIsLoading(false)
    })
  }, [])

  return (
    <div className={DashboardStyles.dashContainer}>
      {loadingContext.isLoading ? (
        <Stack spacing={5} style={{ marginTop: "0.5rem" }}>
          <Skeleton variant="text" animation="wave" width={350} height={28.8} />
          <Skeleton variant="text" animation="wave" width={350} height={28.8} />
          <Skeleton variant="rounded" animation="wave" width={1000} height={250} style={{ marginTop: "8.5rem" }} />
        </Stack>
      ) : (
        <>
          {showChecklistCard ? (
            <section>
              <ChecklistCard list={checkList} name={profile.firstName} hideCardCallback={fetchCheckList} />
            </section>
          ) : null}
          <section>
            <div className={DashboardStyles.dashItem}>
              <div className={DashboardStyles.dashStat}>
                <p>
                  Credit Usage
                  <BasicTooltip
                    tooltip={
                      <span>
                        {`Total credits consumed in the current billing cycle. Credits are applied exclusively to LLM usage, with varying consumption rates depending on the specific LLM. `}
                        <a
                          style={{ color: "#fff" }}
                          href="https://help.vextapp.com/en/articles/9418796-what-is-credit-and-how-is-it-calculated"
                          target="blank"
                        >
                          Learn more.
                        </a>
                      </span>
                    }
                  />
                </p>
                <p>{`${account.currentCredits?.toLocaleString()} / ${account.maxCredits?.toLocaleString()} Credits`}</p>
              </div>
              <LinearProgress count={(account.currentCredits / account.maxCredits) * 100} style={{ mt: "0.5rem" }} />
              {/* [2024-04-24] Stripe not implement yet */}
              {/* <div style={{ textAlign: "end" }}>
                <Button
                  sx={{ fontSize: "0.7rem", mt: 1, p: 0, textDecoration: "underline", textTransform: "none" }}
                  onClick={() => toggleDialog("addQueries")}
                >
                  Add More Queries
                </Button>
              </div> */}
            </div>
            {/* For Queries (future) */}
            {/* <div className={DashboardStyles.dashItem}>
              <div className={DashboardStyles.dashStat}>
                <p>
                  Queries
                  <BasicTooltip
                    tooltip="Total credits consumed in the current billing cycle. Credits are applied exclusively to LLM usage, with varying consumption rates depending on the specific LLM."
                  />
                </p>
                <p>{`${account.currentQueries?.toLocaleString()} / ${account.maxQueries?.toLocaleString()} Queries`}</p>
              </div>
              <LinearProgress count={(account.currentQueries / account.maxQueries) * 100} style={{ mt: "0.5rem" }} />
            </div> */}
            <div className={DashboardStyles.dashItem}>
              <div className={DashboardStyles.dashStat}>
                <p>
                  Data Storage
                  <BasicTooltip
                    tooltip="The total amount of data storage used within this billing cycle. This is calculated across all of your AI projects/data."
                  />
                </p>
                <p>{`${account.currentStorage?.toLocaleString()} GB / ${account.maxStorage?.toLocaleString()} GB`}</p>
              </div>
              <LinearProgress count={(account.currentStorage / account.maxStorage) * 100} />
            </div>
            {/* if reached limit */}
            {account.currentStorage >= account.maxStorage || account.currentQueries >= account.maxQueries ? (
              <div style={{ maxWidth: "100%", width: "38rem" }}>
                <MessageBox
                  type="bolt"
                  message={
                    <>
                      {`You have reached the maximum limit allowed for your current plan. `}
                      <span
                        style={{ cursor: "pointer", textDecoration: "underline" }}
                        onClick={() => setSubPrompt(true)}
                      >
                        Upgrade plan
                      </span>
                    </>
                  }
                />
              </div>
            ) : null}
            <ConfirmationDialog
              open={dialogState.show && dialogState.type === "addQueries"}
              handlePrimary={handleAddQueries}
              handleSecondary={toggleDialog}
              title="Add More Queries"
              content={
                <>
                  <CreditSlider value={creditSliderValue} onChange={handleChangeSlider} />
                </>
              }
              primaryButtonText={creditSliderValue > 50 ? "Contact Us" : "Update Subscription"}
              primaryButtonVariant="contained"
              secondaryButtonVariant="outlined"
            />
          </section>
          <section style={{ marginTop: "2rem" }}>
            <h3>Resources</h3>
            <div className={DashboardStyles.resourcesContainer}>
              <ResourceCard
                imgSrc="/images/helpcenter.svg"
                altText="Help Center"
                heading="Help Center"
                content="Need help with something? Find your answer at our Help Center."
                link="https://help.vextapp.com/"
                external={true}
              />
              <ResourceCard
                imgSrc="/images/blog.svg"
                altText="Blog"
                heading="Blog"
                content="See our latest updates, sharing, technical content, and more."
                link="https://blog.vextapp.com"
                external={true}
              />
            </div>
          </section>
        </>
      )}
    </div>
  )
}
