import * as React from "react"
import { Chip, Stack, CircularProgress } from "@mui/material"

const statusMapping = {
  p: { label: "In Progress", color: "warning" },
  r: { label: "Ready", color: "success" },
  f: { label: "Failed", color: "error" },
  w: { label: "Warning", color: "warning" },
  u: { label: "Usable", color: "warning" },
  pro: { label: "Pro Plan", color: "primary" },
  enabled: { label: "Enabled", color: "success" },
  beta: { label: "Beta", color: "primary" },
}

export default function Chips({ status, icon, style, customLabel, customColor }) {
  const currentStatus = customLabel && customColor ? { label: customLabel, color: customColor } : statusMapping[status]

  return (
    <Stack direction="row" spacing={1} sx={{ fontSize: "0.8rem" }}>
      {currentStatus && (
        <Chip
          label={currentStatus.label}
          color={currentStatus.color}
          size="small"
          icon={icon ? icon : status === "p" ? <CircularProgress color="inherit" size="0.8rem" thickness={8} /> : null}
          style={style}
        />
      )}
    </Stack>
  )
}
