// Essential Imports
import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button, IconButton, Tooltip } from "@mui/material"
import { MoreHorizOutlined as MoreHorizOutlinedIcon } from "@mui/icons-material"
import { ClickAwayListener } from "@mui/base"

import Chips from "./Chips"
import ConfirmationDialog from "./ConfirmationDialog"
import InputDialog from "./InputDialog"
import { ErrorContext, SuccessContext } from "../../helper/AlertContext"
import { datasetService } from "../../api/services"

// Stylesheet Imports
import "../../styles/Items.css"

const DIALOG_TYPE = {
  EDIT: "type-edit",
  DELETE: "type-delete",
}

export default function DataSourceItem({ dataFrame, status, fetchDataList = () => {} }) {
  const [isLoadingDialog, setIsLoadingDialog] = useState(false)
  const [openDialog, setOpenDialog] = useState({ show: false, type: "" })
  const [showDropdown, setShowDropdown] = useState(false)
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { setSuccess, setSuccessMsg } = useContext(SuccessContext)

  const navigate = useNavigate()

  const handleIconClick = (event) => {
    event.stopPropagation()
    setShowDropdown(!showDropdown)
  }
  const handleItemClick = () => {
    navigate(`/data/${dataFrame.id}`)
  }
  const handleConfirmDelete = async () => {
    try {
      setIsLoadingDialog(true)
      await datasetService.removeDataset(dataFrame.id)
      setSuccess(true)
      setSuccessMsg("Data set successfully deleted.")
      fetchDataList()
      toggleConfirmDialog()
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLoadingDialog(false)
    }
  }
  const handleUpdateDataset = async (payload) => {
    try {
      setIsLoadingDialog(true)
      await datasetService.updateDataset(dataFrame.id, payload)
      setSuccess(true)
      setSuccessMsg("Data set name successfully updated.")
      fetchDataList()
      toggleConfirmDialog()
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLoadingDialog(false)
    }
  }
  const toggleConfirmDialog = (event, type = "") => {
    if (event) {
      event.stopPropagation()
    }
    setOpenDialog({ show: !openDialog.show, type })
  }

  return (
    <>
      <div className="itemsContainer" id={dataFrame.id} onClick={handleItemClick}>
        <div className="dataInfo">
          <h3>{dataFrame.name}</h3>
          <Tooltip title="This is the initialization status of this data container." placement="top">
            <span style={{ display: "inline-block", maxWidth: "fit-content" }}>
              <Chips status={status} />
            </span>
          </Tooltip>
          <p className="appDate">
            <i>
              {"Last changed: "}
              {new Date(dataFrame.updated_time).toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </i>
          </p>
        </div>
        <div style={{ position: "relative" }} className="appAction">
          <IconButton onClick={handleIconClick}>
            <MoreHorizOutlinedIcon className="MoreHorizOutlinedIcon" />
          </IconButton>
          {showDropdown && (
            <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleIconClick}>
              <div className="option">
                <Button
                  onClick={(event) => toggleConfirmDialog(event, DIALOG_TYPE.EDIT)}
                  style={{ justifyContent: "start", textTransform: "none", width: "100%" }}
                >
                  Edit Name
                </Button>
                <Button
                  onClick={(event) => toggleConfirmDialog(event, DIALOG_TYPE.DELETE)}
                  style={{ justifyContent: "start", textTransform: "none", width: "100%", color: "#F44336" }}
                >
                  Delete Data
                </Button>
              </div>
            </ClickAwayListener>
          )}
        </div>
      </div>
      <ConfirmationDialog
        open={openDialog.show && openDialog.type === DIALOG_TYPE.DELETE}
        handlePrimary={handleConfirmDelete}
        handleSecondary={toggleConfirmDialog}
        title="Confirm Delete"
        content={
          <span>
            Are you sure you want to delete <strong>{dataFrame.name}</strong> ? This action cannot be undone.
          </span>
        }
        primaryButtonText={isLoadingDialog ? "Deleting..." : "Delete"}
        primaryButtonColor="error"
        primaryButtonDisabled={isLoadingDialog}
      />
      <InputDialog
        title="Edit Data Set"
        inputTitle="Data Set Name"
        isLoading={isLoadingDialog}
        isOpen={openDialog.show && openDialog.type === DIALOG_TYPE.EDIT}
        onClose={toggleConfirmDialog}
        onSubmit={handleUpdateDataset}
        onLoadSubmitButtonText="Updating..."
        submitButtonText="Update"
      />
    </>
  )
}
