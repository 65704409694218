import request from "../axios"

const requests = {
  getLogList: (params) => {
    const { appId, startDate, endDate } = params
    return request.get(`/log/${appId}/${startDate}/${endDate}`)
  },
  getLogDetail: (logId) => {
    return request.get(`/log/detail/${logId}`)
  },
  downloadFile: (payload, params) => {
    const { appId, startDate, endDate } = payload
    return request.get(`/log/download/${appId}/${startDate}/${endDate}`, { responseType: "blob", params })
  },
}

export default requests
