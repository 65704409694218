// Essential imports
import React, { useState, useContext } from "react"

// Component imports
import { LoadingContext } from "../../helper/LoadingContext"
import { ErrorContext } from "../../helper/AlertContext"
import request from "../../api/axios"

// Library imports
import TablePagination from "@mui/material/TablePagination"

export default function CustomTablePagination({
  page,
  setPage,
  nextPageUrl,
  setNextPageUrl,
  previousPageUrl,
  setPreviousPageUrl,
  data,
  setData,
  dataCount,
  setDataCount,
}) {
  const loadingContext = useContext(LoadingContext)
  const errorContext = useContext(ErrorContext)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const fetchData = async (fetchUrl) => {
    try {
      const { data } = await request.get(fetchUrl)

      setNextPageUrl(data.next)
      setPreviousPageUrl(data.previous)
      setDataCount(data.count)
      return data.results
    } catch (error) {
      console.error("Error:", error)
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
      return []
    }
  }

  // Pagination handlers
  const handleChangePage = async (_, newPage) => {
    loadingContext.setIsLoading(true)
    setPage(newPage)
    if (data) {
      setData([])
    }

    // Next page
    if (newPage > page && nextPageUrl) {
      const result = await fetchData(nextPageUrl)
      setData(result)
      loadingContext.setIsLoading(false)

      // Previous page
    } else if (newPage < page && previousPageUrl) {
      const result = await fetchData(previousPageUrl)
      setData(result)
      loadingContext.setIsLoading(false)
    } else {
      loadingContext.setIsLoading(false)
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <TablePagination
      rowsPerPageOptions={[10]}
      component="div"
      count={dataCount ? dataCount : 10}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}
